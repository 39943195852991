import ApexCharts from 'apexcharts';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';

interface PieChartsProps {
    dashboardData: any;
}

const PieCharts: React.FC<PieChartsProps> = observer(({ dashboardData }) => {
    const chartData = dashboardData && dashboardData.find((item: any) => item.name === 'Quantidade Pedidos');
    const chartPieRef = useRef(null);

    useEffect(() => {
        // Configuração do gráfico
        if (chartPieRef.current) {
            const options = {
                series: chartData ? [parseInt(chartData?.series[0]?.data[0]), parseInt(chartData?.series[1]?.data[0]), parseInt(chartData?.series[2]?.data[0])] : [],
                chart: {
                    type: 'donut',
                    width: 350,
                    height: 350
                },
                colors: ['#f2a73b', '#EAA038', '#C88830'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 350,
                            height: 350
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    show: true,
                    position: 'bottom'
                },
                labels: [chartData?.series[0]?.name, chartData?.series[1]?.name, chartData?.series[2]?.name],
            };

            // Cria e renderiza o gráfico
            const chart = new ApexCharts(chartPieRef.current, options);
            chart.render();

            // Limpeza do gráfico ao desmontar o componente
            return () => {
                chart.destroy();
            };
        }
    }, [chartData]);

    return (
        <div id="chart-pie">
            <div className="flex flex-col justify-center items-center">
                <div ref={chartPieRef}></div>
            </div>
        </div>
    );
});

export default PieCharts;