import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import homeStore from '../../../../stores/homeStore';
import productStore from '../../../../stores/productStore';
import DatePickerComponet from '../../../atoms/DatePickerComponet';
import ContentBox from '../../../molecules/ContentBox';
import { MobileIcon, WebIcon } from '../../../molecules/IconComponent';
import BannerFull from '../../../organisms/ComponentesHome/BannerFull';
import BlogComponent from '../../../organisms/ComponentesHome/BlogComponent';
import BranchComponent from '../../../organisms/ComponentesHome/BranchComponent';
import CategoryCarrosel from '../../../organisms/ComponentesHome/CategoryCarrosel';
import ImagemHomeComponent from '../../../organisms/ComponentesHome/ImagemHomeComponent';
import SlideShowPrincipal from '../../../organisms/ComponentesHome/SlideShowPrincipal';
import ProductCarousel from '../ProductCarousel';
import './styles.scss';
import { formatDateString } from '../../../../utils/dateFunctions';


interface ViewHomeFullProps {
    home?: any;
    isMobile: boolean;
    setMobile: Dispatch<SetStateAction<boolean>>;
}

const ViewHomeFull: React.FC<ViewHomeFullProps> = observer(({ home, setMobile, isMobile }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [productsByCategory, setProductsByCategory] = useState<{ [key: string]: any[] }>({});
    const [hoverElement, setHoverElement] = useState();
    const [loading, setLoading] = useState(false);


    const fetchCategoryProducts = async (categorySlug: string) => {
        // Verifique se já temos produtos para essa categoria
        if (productsByCategory[categorySlug]) {
            setLoading(false);
            return;
        }

        const result = await productStore.getProductByCategory(categorySlug, productStore.defaultparams);
        if (!result.error) {
            setProductsByCategory(prevState => ({
                ...prevState,
                [categorySlug]: result.data.content,
            }));
        }
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        // Para cada componente do tipo PRODUCT_CAROUSEL, buscamos seus produtos
        home.filter((item: any) => item.component.type === "PRODUCT_CAROUSEL")
            .forEach((carouselComponent: any) => {
                fetchCategoryProducts(carouselComponent?.component?.category?.slug);
            });
    }, [home]);



    const renderComponent = (component: any) => {
        const bannerTag = component?.banners?.[0]?.tag;

        if (component?.type === "PRODUCT_CAROUSEL" && !component?.category) {
            return <div className="flex justify-center p-8"><p>Componente sem Categoria </p><b className="pl-1">{component.name}</b></div>;
        }

        if (component.type === "PRODUCT_CAROUSEL") {
            const categorySlug = component?.category?.slug;
            return (
                <div className="max-width-view">
                    <ProductCarousel
                        loading={loading}
                        category={component.category}
                        isMobile={isMobile}
                        products={productsByCategory[categorySlug] || []}
                    />
                </div>
            );
        }

        if (component?.banners?.length === 0) {
            return <div className="flex justify-center p-8"><p>Componente sem imagem </p><b className="pl-1">{component.name}</b></div>;
        }

        // Estrutura switch para lidar com diferentes tipos de banners
        switch (bannerTag) {
            case "SLIDESHOW_PRINCIPAL":
                return <SlideShowPrincipal banner={component.banners[0]} isMobile={isMobile} />;
            case "SLIDESHOW":
                return <SlideShowPrincipal banner={component.banners[0]} isMobile={isMobile} />;
            case "IMAGENS_HOME":
                return <ImagemHomeComponent banner={component.banners[0]} isMobile={isMobile} />;
            case "BLOG":
                return <BlogComponent banner={component.banners[0]} isMobile={isMobile} />;
            case "MARCAS":
                return <div className="py-8 max-width-view"><BranchComponent banner={component.banners[0]} isMobile={isMobile} /></div>;
            case "CATEGORY_CAROUSEL":
                return <div className="py-8  max-width-view"><CategoryCarrosel banner={component.banners[0]} isMobile={isMobile} /></div>;
            case "BANNER_FULL":
                return <div className="py-6 "><BannerFull banner={component.banners[0]} /></div>;
            default:
                return null;
        }
    };


    const handleSubmit = (values: any) => {
        const dateFormat = values && values.date && values?.date?.format('YYYY-MM-DD');

        if (dateFormat) {
            const filterDate = new Date(`${dateFormat}T00:00:00.000Z`);
            homeStore.updateFilterComponent(filterDate, isMobile);
        } else {
            message.error('Selecione uma data para filtrar');
        }
    }

    const initialValues = {
        date: '',
    };

    // Função chamada quando o drag for finalizado
    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        // Caso o item não tenha sido movido
        if (!destination) return;

        // Se o item foi movido para a mesma posição
        if (source.index === destination.index) return;

        // Divida os itens em mobile e web
        const mobileItems = home.filter((item: any) => item.mobile);
        const webItems = home.filter((item: any) => !item.mobile);

        let updatedItems: any[] = [];

        if (isMobile) {
            // Reordene apenas os itens móveis
            const reorderedMobileItems = Array.from(mobileItems);
            const [removed] = reorderedMobileItems.splice(source.index, 1);
            reorderedMobileItems.splice(destination.index, 0, removed);

            // Atualize a ordem dos itens móveis
            updatedItems = reorderedMobileItems.map((item: any, index) => ({
                ...item,
                order: index + 1, // Atualiza o mobileOrder
            }));

            // Manter os itens web sem alteração
            updatedItems = [...updatedItems, ...webItems];
        } else {
            // Reordene apenas os itens web
            const reorderedWebItems = Array.from(webItems);
            const [removed] = reorderedWebItems.splice(source.index, 1);
            reorderedWebItems.splice(destination.index, 0, removed);

            // Atualize a ordem dos itens web
            updatedItems = reorderedWebItems.map((item: any, index) => ({
                ...item,
                order: index + 1, // Atualiza o webOrder
            }));

            const copyListMobile = JSON.parse(JSON.stringify(mobileItems));

            // Manter os itens mobile sem alteração
            updatedItems = [...copyListMobile, ...updatedItems];
        }

        // Atualize a ordem no store com base nos itens reorganizados
        homeStore.updateHomeOrder(updatedItems);
    };

    const deleteItem = (item: any) => {
        homeStore.removeComponent(item.componentUuid);
    }

    const handleMoveUp = (index: number) => {
        if (index === 0) return;

        const reorderedItems = Array.from(home);
        const [item] = reorderedItems.splice(index, 1);
        reorderedItems.splice(index - 1, 0, item);

        const updatedItems = reorderedItems.map((item: any, idx) => ({
            ...item,
            order: idx + 1,
        }));

        homeStore.updateHomeOrder(updatedItems);
    };

    const handleMoveDown = (index: number) => {
        if (index === home.length - 1) return;
        const reorderedItems = Array.from(home);
        const [item] = reorderedItems.splice(index, 1);
        reorderedItems.splice(index + 1, 0, item);

        const updatedItems = reorderedItems.map((item: any, idx) => ({
            ...item,
            order: idx + 1,
        }));
        homeStore.updateHomeOrder(updatedItems);
    };

    return (
        <div>
            <ContentBox className="mb-4 p-4" borderCustom='rounded-b-lg'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                //validationSchema={validationSchema}
                >
                    {({ values }) => (
                        <Form>
                            <div className="flex items-end gap-4 px-4">
                                <DatePickerComponet label="Data Inicio" name="date" isRange={false} />
                                <div>
                                    <Button className="w-full" onClick={() => handleSubmit(values)} type="primary">Filtrar</Button>
                                </div>
                                <div className="flex gap-2">
                                    <div className="flex gap-3">
                                        <div onClick={() => setMobile(true)} className={`p-1.5 px-1.5 flex justify-center items-center cursor-pointer rounded ${isMobile ? 'border-2 border-blue-500' : 'border border-slate-200'}`}>
                                            <MobileIcon />
                                        </div>
                                        <div onClick={() => setMobile(false)} className={`p-1.5 px-1.5 flex justify-center items-center cursor-pointer rounded ${!isMobile ? 'border-2 border-blue-500' : 'border border-slate-200'}`}>
                                            <WebIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik >

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                onMouseLeave={() => setHoverElement(undefined)}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ padding: '16px' }}
                            >
                                {home.filter((item: any) => item.mobile === isMobile).map((item: any, index: number) => (
                                    <Draggable key={item.componentUuid} draggableId={item.componentUuid.toString()} index={index}>
                                        {(provided) => (
                                            <div
                                                key={index}
                                                onMouseEnter={() => {
                                                    setHoverElement(item);
                                                }}
                                                className="container-component"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                }}
                                            >
                                                {hoverElement === item &&
                                                    <>
                                                        <div className="options-home">
                                                            <div className="flex option-list">
                                                                <div>{item.component.name}</div>
                                                                <div onClick={() => deleteItem(item)} className="home-icon"><DeleteOutlined /></div>
                                                                <div className="home-icon" onClick={() => handleMoveUp(index)}>
                                                                    <CaretUpOutlined />
                                                                </div>
                                                                <div className="home-icon" onClick={() => handleMoveDown(index)}>
                                                                    <CaretDownOutlined />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* {item.component.type !== "BANNER_CAROUSEL" &&
                                                            <div className="options-home-date">
                                                                <div className="option-home-date-text">
                                                                    <p>{formatDateString(item.component.minDate)}</p>
                                                                </div>
                                                            </div>
                                                        } */}
                                                    </>
                                                }

                                                <div className={`${isMobile ? 'mobile-size m-auto p-3' : ''}`}>
                                                    {renderComponent(item.component)}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </ContentBox>
        </div>
    );
});

export default ViewHomeFull;