import { Button, Drawer, message } from 'antd';
import { Form, Formik } from 'formik'; // Removi o useFormikContext daqui
import { observer } from 'mobx-react-lite';
import React from 'react';
import bannerStore from '../../../../stores/bannerStore';
import { v4 as uuidv4 } from 'uuid';
import InputComponent from '../../../atoms/InputComponet';
import ImageUploadComponent from '../../../atoms/UploadComponent';
import { canChangeBannerType, canChangeBannerTypeAdd } from '../../../../utils/bannerFunctions';
import { validationSchema } from './schema';
import DatePickerRangeComponet from '../../../atoms/DatePickerRangeComponet';
import { formattedDates } from '../../../../utils/functions';

export const statusOptions = [
    {
        value: 'PENDING',
        label: 'Pendente'
    },
    {
        value: 'ACCEPTED',
        label: 'Aprovado'
    },
    {
        value: 'REJECTED',
        label: 'Reprovado'
    }
];


const DrawerImage: React.FC = observer(({ isModalOpen, setIsModalOpen, tag }: any) => {
    const { banner } = bannerStore;

    const handleSubmit = async (values: any) => {
        const currentImages = banner.files.length;
        const formattedDate = formattedDates(values?.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];

        if (!canChangeBannerTypeAdd(tag, currentImages)) {
            message.error(`Para selecionar o tipo ${tag}, remova algumas imagens para não exceder o limite.`);
        } else {
            const generateUuid = uuidv4();


            const newfile = {
                ...values,
                file: values.file[0],
                uuid: generateUuid,
                initialDate: `${dateInitial}T00:00:00.000-03:00`,
                finalDate: `${dateFinal}T23:59:59.999-03:00`
            }

            const newfilePreview = {
                ...values,
                file: {
                    ...values.file[0],
                    path: values.file[0]?.preview
                },
                uuid: generateUuid,
                initialDate: `${dateInitial}T00:00:00.000-03:00`,
                finalDate: `${dateFinal}T23:59:59.999-03:00`
            }

            bannerStore.addBannerFile(newfile, newfilePreview);
        }
    };

    const initialValuesForm = {
        name: '',
        ordering: '',
        link: '',
        file: '',
        date: '',
    };

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValuesForm} onSubmit={async (values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
            setIsModalOpen(false);
        }}
            enableReinitialize>
            {({ submitForm, resetForm }) => ( // Aqui está o submitForm
                <Drawer
                    title="Cadastro de Imagem"
                    width={500}
                    onClose={() => {
                        setIsModalOpen(false);
                        resetForm();
                    }}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}> {/* Agora funciona */}
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex gap-4 flex-col">
                            <ImageUploadComponent maxCount={1} name="file" label="Banner Vitrine" />
                            <InputComponent size="middle" label="Nome" name="name" id="name" />
                            <InputComponent size="middle" label="Ordenação" name="ordering" id="ordering" />
                            <DatePickerRangeComponet size="middle" label="Data Inicial e Final" name="date" />
                            <InputComponent size="middle" label="Link" name="link" id="link" />
                            <InputComponent size="middle" label="Descrição" name="description" id="description" />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
});

export default DrawerImage;
