import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class CustomerService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CUSTOMERS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async searchCustomer(name: any, term: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CUSTOMERS}/search?size=1000&page=0&sort=created,asc&search=;${term};"${name}"`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async updateMulitpleCustomer(data: any, uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.patch(`/customers/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao atualizar Customer" }; // Retorna um objeto de erro
        }
    }

    async getCustomerByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CUSTOMERS}/${uuid}?by=uuid`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async createCustomer(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.CUSTOMERS}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editCustomer(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.CUSTOMERS}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async deleteCustomer(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.CUSTOMERS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    /**Busca todos os endereços */
    async getAllAddress(uuid: string) {
        try {
            const response = await BaseAPI.get(`customers/${uuid}/address`);
            if (response.status === 200) return response.data;
            return { error: "Falha ao buscar endereços" };
        } catch (e) {
            return { error: "Falha ao buscar endereços" };
        }
    }
}

export default new CustomerService();