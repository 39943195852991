import { AppstoreOutlined, FileDoneOutlined, FileImageOutlined, GoldOutlined, HomeOutlined, InboxOutlined, MailOutlined, RocketOutlined, SettingOutlined, TagsOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, type MenuProps } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../protected/ProviderAuth';
import './styles.scss';

type MenuItem = Required<any>['items'][number];
interface MenuComponentProps {
    collapsed?: boolean;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ collapsed }) => {
    const [current, setCurrent] = useState('1');
    const navigate = useNavigate();
    const { hasPermission, isColumnVisible, hasPermissionList } = useAuth();

    const items: MenuItem[] = [
        {
            key: 'dashboard',
            label: 'Dashboard',
            icon: <HomeOutlined />,
            visible: true,
        },
        {
            key: 'administradores-main',
            label: 'Administradores',
            icon: <TeamOutlined />,
            visible: true,
            children: [
                { key: 'administradores', label: 'Administradores', visible: true, },
                { key: 'perfis', label: 'Perfis', visible: true, },
            ],
        },
        {
            key: 'banners',
            label: 'Banners',
            icon: <FileImageOutlined />,
            visible: true,
        },
        {
            key: 'pedidos',
            label: 'Pedidos',
            icon: <FileDoneOutlined />,
            visible: true,
        },
        {
            key: 'produtos-main',
            label: 'Produtos',
            icon: <InboxOutlined />,
            visible: true,
            children: [
                { key: 'produtos', label: 'Produtos', visible: true, },
                { key: 'produtos-variacao', label: 'Produtos Variação', visible: true, },
                { key: 'avaliacoes', label: 'Avaliações', visible: true, },
                { key: 'especificacao', label: 'Especificação', visible: true, },
                { key: 'produtos/categorias', label: 'Produtos por Categoria', visible: true, },
            ],
        },
        {
            key: 'atributos',
            label: 'Atributos',
            icon: <InboxOutlined />,
            visible: true,
            children: [
                { key: 'cores', label: 'Cores', visible: true, },
                { key: 'voltagem', label: 'Voltagem', visible: true, },
                { key: 'material', label: 'Materias', visible: true, },
                { key: 'tamanhos', label: 'Tamanho', visible: true, },
            ],
        },
        {
            key: 'categorias',
            label: 'Categorias',
            icon: <GoldOutlined />,
            visible: true,
        },
        {
            key: 'parcelas',
            label: 'Condição de Pagamento',
            icon: <GoldOutlined />,
            visible: true,
        },
        {
            key: 'client',
            label: 'Cliente',
            icon: <UserOutlined />,
            visible: true,
            children: [
                { key: 'cliente', label: 'Clientes', visible: true, },
                { key: 'canal', label: 'Canais', visible: true, },
                { key: 'subcanal', label: 'SubCanal', visible: true },
            ],
        },
        {
            key: 'config',
            label: 'Configuração Loja',
            icon: <SettingOutlined />,
            visible: true,
            children: [
                { key: 'configuracao', label: 'Configurações', visible: true },
                { key: 'institucional', label: 'Institucional', visible: true },
                { key: 'selos', label: 'Selos', visible: true },
            ],
        },
        {
            key: 'shipping',
            label: 'Frete',
            icon: <RocketOutlined />,
            visible: true,
            children: [
                { key: 'fretes', label: 'Fretes', visible: true },
                { key: 'upload-frete', label: 'Upload Fretes', visible: true },
            ],
        },
        {
            key: 'home-main',
            label: 'Home',
            icon: <AppstoreOutlined />,
            visible: true,
            children: [
                { key: 'home', label: 'Home', visible: true },
                { key: 'componentes', label: 'Componentes', visible: true },
            ],
        },
        {
            key: 'promotion',
            label: 'Promoção',
            icon: <TagsOutlined />,
            visible: true,
            children: [
                { key: 'campanhas', label: 'Campanhas', visible: true },
                { key: 'cupons', label: 'Cupons', visible: true },
            ],
        },
        {
            key: 'reports',
            label: 'Relatórios',
            icon: <SettingOutlined />,
            visible: true,
            children: [
                { key: 'visao-geral', label: 'Visão Geral', visible: true },
                { key: 'relatorio-carrinho', label: 'Carrinho Abandonado', visible: true },
                { key: 'relatorio-produtos-mais-comprados', label: 'Produtos mais Comprados', visible: true },
                { key: 'vendas-por-categoria', label: 'Vendas por Categoria', visible: true },
                { key: 'relatorio-campanhas', label: 'Campanhas', visible: true },
                { key: 'relatorio-avaliacoes', label: 'Relatório Avaliações', visible: true },
                { key: 'relatorio-cupons', label: 'Cupons', visible: true },
                { key: 'log-de-acesso', label: 'Log de Acesso', visible: true },
            ],
        },
        {
            key: 'template',
            label: 'Template E-mail',
            icon: <MailOutlined />,
            visible: true
        },
        // {
        //     key: 'log-sistema',
        //     label: 'Logs do Sistema',
        //     icon: <SettingOutlined />,
        //     visible: true,
        // },
    ];


    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };


    const filteredItems = items
        .filter(item => item.visible) // Filtra os itens principais com base na visibilidade
        .map(item => ({
            ...item,
            children: item.children?.filter((child: any) => child.visible), // Filtra os sub-itens (children)
        }));

    return (
        <div className="flex flex-col bg-white dark:bg-charcoal custom-menu-color">
            {!collapsed ?
                <div className="menu-logo flex flex-col items-center pt-6">
                    <img src="/assets/3c-logo.png" alt="Logo" style={{ width: '150px' }} />
                </div> :
                <div className="menu-logo flex flex-col items-center pt-6">
                    <img src="/assets/3c-logo-mini.png" alt="Logo" style={{ width: '40px', maxWidth: '40px' }} />
                </div>
            }

            <Menu
                theme={'light'}
                onClick={onClick}
                style={collapsed ? { width: 80 } : { width: 256 }}
                defaultOpenKeys={['sub1']}
                selectedKeys={[current]}
                inlineCollapsed={collapsed}
                mode="inline"
                items={filteredItems}// Filtra o item principal
            />
        </div>
    );
};

export default MenuComponent;