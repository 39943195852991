import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import CustomerService from '../services/CustomerService';
import { PaginationParams } from '../types';
import { transformToValueLabel } from '../utils/functions';


class CustomerStoreStore {
    loading: boolean = false;
    customer: any;
    customerList: any = [];
    selectCustomerList: any = [];
    addressList: any = [];
    sort: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.customer = undefined;
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await CustomerService.getList(params);

        if (!response.data.error) {
            this.customerList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        }
        this.loading = false;

        return response;
    }


    async searchCustomer(searchTerm: string, term: string) {
        this.loading = true;
        const response = await CustomerService.searchCustomer(searchTerm, term);

        if (!response.data.error && response.data.content.length > 0) {
            const selectCustomerList = transformToValueLabel(response.data.content, 'uuid', 'firstName');

            if (response.data.content.length === 0) {
                message.error('Nenhum cliente foi encontrado para a busca realizada.');
            }
            return selectCustomerList;
        }
        this.loading = false;
    }



    async findCustomerByFile(searchTerm: string, term: string) {
        this.loading = true;
        const response = await CustomerService.searchCustomer(searchTerm, term);

        if (!response.error) {
            return response.data
        }
    }


    async updateMultiple(searchTerm: string, term: string) {
        this.loading = true;
        const response = await CustomerService.searchCustomer(searchTerm, term);

        if (!response.data.error) {
            const selectCustomerList = transformToValueLabel(response.data.content, 'uuid', 'firstName');

            if (response.data.content.length === 0) {
                message.error('Nenhum cliente foi encontrado para a busca realizada.');
            }
            return selectCustomerList;
        }
        this.loading = false;
    }


    async updateMulitpleCustomer(data: any, uuid: string) {
        const response = await CustomerService.updateMulitpleCustomer(data, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(response?.details?.response?.data?.message);
            } else {
                message.success('Cliente Atualizado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }


    async getCustomerByUuid(uuid: string) {
        this.loading = true;
        const response = await CustomerService.getCustomerByUuid(uuid);
        const result = await CustomerService.getAllAddress(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar cliente');
                return;
            }

            if (!result.error) {
                this.addressList = result;
            }
            this.customer = response.data;
            this.loading = false;
        });

        return response.data;
    }

    async createCustomer(data: any) {
        this.loading = true;
        const response = await CustomerService.createCustomer(data);

        if (response.error) {
            message.error('Erro ao criar Cliente');
        } else {
            message.success('Cliente cadastrado com sucesso!');
        }

        this.loading = false;
        return response;
    }

    async editCustomer(data: any, uuid: string | undefined) {
        this.loading = true;
        const response = await CustomerService.editCustomer(data, uuid);

        if (response.error) {
            message.error('Erro ao editar Cliente');
        } else {
            message.success('Cliente editado com sucesso!');
        }

        this.loading = false;
        return response;
    }

    async deleteCustomer(uuid: string) {
        this.loading = true;
        const response = await CustomerService.deleteCustomer(uuid);
        if (response.error) {
            message.error(`Erro ao deletar Cliente`);
        } else {
            message.success('Cliente deletado com sucesso!');
        }
        this.customer = response.data;
        this.loading = false;
        return response;
    }
}

const customerStore = new CustomerStoreStore();
export default customerStore;