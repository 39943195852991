import { ArrowUpOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Statistic, Table } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../config/config';
import useQueryParams from '../../../hooks/useQueryParams';
import OrderModel from '../../../models/OrderModel';
import { PermissionReports } from '../../../permissions/PermissionReports';
import customerStore from '../../../stores/customerStore';
import reportStore from '../../../stores/reportStore';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { convertToDayjs, formatCurrency, formattedDates, getFirstFilterDates, getSortOrder, getUniquePropertyFilters } from '../../../utils/functions';
import AutocompleteComponent from '../../atoms/AutocompleteComponent';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import DatePickerComponet from '../../atoms/DatePickerComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';

interface DataType {
    customer: any;
    document: string;
    type: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    to: string;
    from: string;
    size?: number;
    page?: number;
    labelTerm?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Relatório Carrinho Abandonado</p>,
    },
]

const ReportCartAbandonment: React.FC = observer(() => {
    const { reportList, reportTotal, loading, page, totalElements, sort } = reportStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
    const [initialDate, finalDate] = getFirstFilterDates();
    const { hasPermission } = useAuth();

    const initialValues = {
        date: queryParams.from && queryParams.to ? [convertToDayjs(queryParams.from), convertToDayjs(queryParams.to)] : [convertToDayjs(initialDate), convertToDayjs(finalDate)], // Define o valor como um array com objetos moment
        searchTerm: typeof queryParams.searchTerm === 'string'
            ? { value: queryParams.searchTerm, label: queryParams.labelTerm }
            : { value: '', label: '' },
    };

    useEffect(() => {
        const initialParams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            customer: queryParams.labelTerm || undefined,
            from: queryParams.from ? queryParams.from + 'T00:00:00.556' : initialDate + 'T00:00:00.556',
            to: queryParams.to ? queryParams.to + 'T00:00:00.556' : finalDate + 'T00:00:00.556',
            sort: "created",
            page: queryParams.page || "0",
            size: queryParams.size || DEFAULT_PAGE_SIZE
        }

        const fetchData = async () => {
            if (hasPermission(PermissionReports.ABANDONED_CARTS_GENERAL)) {
                reportStore.getList(initialParams);
                reportStore.getReportTotal(initialParams);
            }
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Cliente',
            dataIndex: 'customer', // Verifique a estrutura dos dados
            key: 'customer.firstName',
            filters: getUniquePropertyFilters<any, 'customer', 'firstName'>(reportList, 'customer', 'firstName'),
            onFilter: (value, record) => record.customer.firstName.indexOf(value as string) === 0, // Acesse o nome do canal
            defaultSortOrder: getSortOrder('code', queryParams.sort),
            render: (item) => {
                return (
                    <>{item && <p>{item?.firstName}</p>}</>
                );
            },
        },
        {
            title: 'Total',
            dataIndex: 'total', // Verifique a estrutura dos dados
            key: 'total',
            width: 200,
            render: (total) => {
                return (
                    <>{total && <p>{formatCurrency(total)}</p>}</>
                );
            },
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            width: 200,
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
    ];


    // Faz o filtro da tabela
    const handleSubmit = async (values: { searchTerm: { value: string; label: string }; date: any }) => {
        const formattedDate = formattedDates(values.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];


        const params = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            customer: values.searchTerm.label || "",
            from: dateInitial + 'T00:00:00.556',
            to: dateFinal + 'T00:00:00.556',
            sort: queryParams.sort || sort,
            page: queryParams.page || "0",
            size: queryParams.size || DEFAULT_PAGE_SIZE
        }

        const newParams: any = {
            searchTerm: values.searchTerm.value || undefined,
            labelTerm: values.searchTerm.label || undefined,
            to: dateFinal || undefined,
            from: dateInitial || undefined,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await reportStore.getList(params);
        await reportStore.getReportTotal(params);
    };


    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL
        setAutocompleteOptions([]);

        const clearparams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            "customer": '',
            "from": initialDate + 'T00:00:00.556',
            "to": finalDate + 'T00:00:00.556',
            "sort": "created",
            "page": "0",
            "size": DEFAULT_PAGE_SIZE
        }

        await reportStore.getList(clearparams);
        await reportStore.getReportTotal(clearparams);
    };


    const handleSearch = async (searchText: string) => {
        if (searchText.length > 3) {
            try {
                const response = await customerStore.searchCustomer(searchText, 'firstName');
                setAutocompleteOptions(response);
                // Aqui você pode mapear a resposta para opções, se necessário
            } catch (error) {
                console.error('Erro ao buscar cliente:', error);
            }
        }
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        //@ts-ignore
        const params = buildParams(pagination, queryParams);
        await reportStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        queryParams: QueryParams
    ) => {
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;


        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            "customer": queryParams.labelTerm || undefined,
            "from": queryParams.from + 'T00:00:00.556',
            "to": queryParams.to + 'T00:00:00.556',
            "sort": "created"
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            customer: params.customer,
            from: queryParams.from,
            to: queryParams.to,
            sort: params.sort,
        });

        return params;
    };

    const handleExport = async () => {
        const defaultParams = {
            '@class': 'br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO',
            customer: queryParams.labelTerm || undefined,
            from: queryParams.from ? queryParams.from + 'T00:00:00.556' : initialDate + 'T00:00:00.556',
            to: queryParams.to ? queryParams.to + 'T00:00:00.556' : finalDate + 'T00:00:00.556',
            size: 9999,
            page: 0,
            sort: "created"
        }

        const dataExport = await reportStore.getList(defaultParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: OrderModel) => ({
                customer: item.customer?.firstName,
                total: formatCurrency(Number(item.total)),
                channel: item?.customer?.subChannel?.channel?.name,
                subChannel: item?.customer?.subChannel?.name,
                created: formatDateString(item.created?.toString() ?? ""),
            }));

            exportToExcel(listExport, ["Cliente", "Total", 'Canal', 'SubCanal', "Data de Criação"], "Tabela de Relatório Carrinho Abandonado.xlsx");
        }
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            <Permission permissionKeys={[PermissionReports.ABANDONED_CARTS_GENERAL]} fallback={NotPermissionComponent()}>
                <ContentBox className="mb-4 p-6" title="Relatório Carrinho Abandonado" description='Informações sobre abandono de carrinho'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (  // Passa o resetForm do render prop de Formik
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <Permission permissionKeys={[PermissionCustomer.GET]} inputType name="Buscar-Clientes">
                                            <AutocompleteComponent
                                                label="Buscar Clientes"
                                                name="searchTerm"
                                                placeholder="Digite o nome do cliente"
                                                //@ts-ignore
                                                fetchOptions={handleSearch} // Passa a função de busca
                                                options={autocompleteOptions}
                                            />
                                        </Permission>
                                    </div>
                                    <div className="w-full">
                                        <DatePickerComponet label="Data inicial" name="date" />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6">
                    <div className="flex items-end w-full gap-4 pb-4 items-center justify-between">
                        <p>Relatório Carrinho Abandonado</p>

                        <div className="flex gap-4">
                            <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                        </div>
                    </div>

                    <div className="flex mb-4 gap-8 justify-between px-8 py-4 bg-slate-50 rounded-lg">
                        <Statistic
                            title="Porcentagem de abandono"
                            value={`${reportTotal?.abandonedCartPercentage || '0'}`}
                            precision={2}
                            valueStyle={{ color: '#2d6100' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />

                        <Statistic title="Total de Valor de Carrinhos Abandonado" value={`${formatCurrency(reportTotal?.abandonedCartTotalValue)}`} />
                        <Statistic title="Quantidade de Carrinhos Abandonados" value={`${reportTotal?.abandonedCartTotalCount || "0"}`} />
                    </div>

                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={reportList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        onChange={onChange}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: DEFAULT_PAGE_SIZE,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>
        </>
    );
});

export default ReportCartAbandonment;