import React from 'react';
import { Button, ButtonProps } from 'antd';
import './styles.scss';
import { DeleteOutlined, PlusOutlined, FileExcelOutlined } from '@ant-design/icons';


//@ts-ignore
interface ActionButtonProps extends ButtonProps {
    actionType: 'create' | 'delete' | 'export' | 'primary' | 'outline' | 'disabled';
    icon?: boolean;
    htmlType?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({ actionType, icon, htmlType, children, ...props }) => {
    let buttonClass = '';

    switch (actionType) {
        case 'outline':
            buttonClass = 'outline-button';
            break;
        case 'create':
            buttonClass = 'create-button';
            break;
        case 'delete':
            buttonClass = 'delete-button';
            break;
        case 'export':
            buttonClass = 'export-button';
            break;
        case 'primary':
            buttonClass = 'primary-button';
            break;
        case 'disabled':
            buttonClass = 'disabled-button';
            break;
        default:
            break;
    }

    return (
        //@ts-ignore
        <Button htmlType={htmlType ? 'submit' : undefined} className={buttonClass} {...props}>
            {icon && actionType === 'delete' &&
                <DeleteOutlined />
            }

            {icon && actionType === 'create' &&
                <PlusOutlined />
            }

            {icon && actionType === 'export' &&
                <FileExcelOutlined />
            }
            {children}
        </Button>
    );
};

export default ActionButton;