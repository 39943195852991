import { Button, Drawer } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import productStore from '../../../../../stores/productStore';
import InputComponent from '../../../../atoms/InputComponet';
import SelectComponent from '../../../../atoms/SelectComponent';
import { validationSchema } from './schema';


export const MultipleRule = [
    { value: 'HE', label: 'Ativo' },
    { value: 'IC', label: 'Inativo' }
];


interface DrawerPriceProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerPrice: React.FC<DrawerPriceProps> = ({ isModalOpen, setIsModalOpen }) => {
    const { unitSelected } = productStore;

    const handleSubmit = async (values: any) => {
        const createProps = {
            ...values,
            uuid: uuidv4(),
            nameMeasurement: values?.nameMeasurement?.label,
            unit: values?.nameMeasurement?.option,
            deleted: false,
            show: true
        };

        const response = await productStore.addPrice(createProps);
        return response;
    };

    const initialValuesForm = {
        center: '',
        tableId: '',
        minimumPrice: '',
        price: '',
        initialAmount: '',
        nameMeasurement: { value: '', label: '' },
    };

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValuesForm} onSubmit={async (values, { resetForm }) => {
            const response = await handleSubmit(values);
            if (response === 'Sucess') {
                resetForm();
                setIsModalOpen(false);
            }
        }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastrar de Preço"
                    width={540}
                    onClose={() => {
                        setIsModalOpen(false);
                        resetForm();
                    }}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}>
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex gap-4 flex-col">
                            <InputComponent size="middle" label="Centro" name="center" id="center" />
                            <InputComponent size="middle" label="Tabela de preço" name="tableId" id="tableId" />

                            <SelectComponent size="middle" label="Unidade de Medida" name="nameMeasurement" options={unitSelected} />

                            <InputComponent size="middle" label="Preço Mínimo" name="minimumPrice" id="minimumPrice" />
                            <InputComponent size="middle" label="Preço Máximo" name="price" id="price" />

                            <InputComponent size="middle" label="Quantidade Inicial" name="initialAmount" id="initialAmount" />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
};

export default DrawerPrice;
