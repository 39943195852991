export const BannerType = [
    {
        value: 'SLIDESHOW_PRINCIPAL',
        label: 'Slideshow Principal',
        img: '/assets/banners/slideshow_principal.png',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ',
        maxImage: '1/∞',
        dimension: '1920 x 600',
        maxFile: 9999,
    },
    {
        value: 'SLIDESHOW',
        label: 'Slideshow',
        img: '/assets/banners/slideshow_principal.png',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ',
        maxImage: '1/∞',
        dimension: '1328 x 450',
        maxFile: 9999,
    },
    {
        value: 'IMAGENS_HOME',
        label: 'Imagens Home',
        img: '/assets/banners/slideshow_principal.png',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ',
        maxImage: '1/2',
        dimension: '1328 x Livre',
        maxFile: 2,
    },
    {
        value: 'BLOG',
        label: 'Blog',
        img: '/assets/banners/slideshow_principal.png',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ',
        maxImage: '3/3',
        dimension: '432 x 266',
        maxFile: 3,
    },
    {
        value: 'MARCAS',
        label: 'Carrosel de Marcas',
        img: '/assets/banners/slideshow_principal.png',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ',
        maxImage: '6/∞',
        dimension: '110 x 110',
        maxFile: 6,
    },
    {
        value: 'CATEGORY_CAROUSEL',
        label: 'Categoria Carosel',
        img: '/assets/banners/slideshow_principal.png',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ',
        maxImage: '6/∞',
        dimension: '110 x 110',
        maxFile: 6,
    },
    {
        value: 'BANNER_FULL',
        label: 'Banner 100%',
        img: '/assets/banners/slideshow_principal.png',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem ',
        maxImage: '1/1',
        dimension: '1920 x 600',
        maxFile: 1,
    },
];


export const StatusActive = [
    { value: false, label: 'Todos' },
    { value: 'true', label: 'Ativo' },
    { value: 'false', label: 'Inativo' }
];


export const Status = [
    { value: 'true', label: 'Ativo' },
    { value: 'false', label: 'Inativo' }
];

export const StatusBoolean = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' }
];

export const YesOrNot = [
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' }
];

export const YesOrNotOrAll = [
    { value: '', label: 'Todos' },
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' }
];


export const Responsive = [
    { value: true, label: 'Responsivo' },
    { value: false, label: 'Web' }
];

export const ResponsiveString = [
    { value: 'true', label: 'Responsivo' },
    { value: 'false', label: 'Web' }
];

export const ComponentesSelect = [
    { value: 'BANNER_CAROUSEL', label: 'Banner' },
    { value: 'PRODUCT_CAROUSEL', label: 'Carrosel' }
];

export const campaignItems = [
    {
        label: 'Desconto Percentual',
        value: 'Desconto Percentual X',
    },
    {
        label: 'Desconto Absoluto',
        value: 'Desconto Absoluto X',
    },
];


export const typePromotion = [
    {
        label: "Categoria",
        value: "CATEGORIA",
    },
    {
        label: "Produto",
        value: "PRODUTO",
    },
]

export const MultipleRule = [
    { value: 'HE', label: 'Ativo' },
    { value: 'IC', label: 'Inativo' }
];


export const userStatusOptions = [
    { value: 'NOVO', label: 'Novo' },
    { value: 'PENDENTE', label: 'Pendente Consulta Externa' },
    { value: 'AAC', label: 'Pendente Análise de Crédito' },
    { value: 'BLOQUEADO', label: 'Bloqueado' },
    { value: 'AS', label: 'Aguardando Segmentação' },
    { value: 'APROVADO', label: 'Aprovado' },
    { value: 'REPROVADO', label: 'Reprovado' },
];


export const ratingSelect = [
    {
        value: '',
        label: 'Todos',
    },
    {
        value: '1',
        label: '1 Estrela',
    },
    {
        value: '2',
        label: '2 Estrelas',
    },
    {
        value: '3',
        label: '3 Estrelas',
    },
    {
        value: '4',
        label: '4 Estrelas',
    },
    {
        value: '5',
        label: '5 Estrelas',
    },
];


export const orderStatusRules = [
    { id: '1', value: 'ANALYSIS', label: 'Pedido Realizado' },
    { id: '2', value: 'PENDING_PAYMENT', label: 'Pendente de Pagamento' },
    { id: '3', value: 'PAID', label: 'Pagamento Autorizado' },
    { id: '4', value: 'BILLED', label: 'Pedido Faturado' },
    { id: '5', value: 'SENT', label: 'Pedido Saiu Para Entrega' },
    { id: '6', value: 'DELIVERED', label: 'Pedido Entregue' },
    { id: '7', value: 'FINISHED', label: 'Pedido Concluído' },
    { id: '8', value: 'RETURNED', label: 'Retornado' },
    { id: '9', value: 'CANCELED', label: 'Pedido Cancelado' },
];