import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import TemplateService from '../services/TemplateService';
import { PaginationParams } from '../types';
import { message } from 'antd';


class TemplateStore {
    loading: boolean = false;
    templateList: any = [];
    template: any;
    bodyTemplate: any;
    sort: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'type,desc',
        search: 'example:false',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.template = undefined;
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await TemplateService.getList(params);

        if (!response.data.error) {
            // Lista de tipos que você deseja remover
            const typesToRemove = ["RETURNED", "CART", "SEPARATED", "WELCOME"];
            // Filtrando o array para remover os itens indesejados
            const filteredArray = response.data.content.filter((item: any) => !typesToRemove.includes(item.type));
            this.templateList = filteredArray;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        }
        this.loading = false;
    }


    async getTemplateByUuid(uuid: string) {
        this.loading = true;
        const response = await TemplateService.getTemplateByUuid(uuid);

        if (response.error) {
            message.error('Erro ao buscar Produto');
            return;
        }

        this.template = response.data;
        this.loading = false;
        return response.data;
    }


    async senEmailTesting(data: any) {
        const response = await TemplateService.sendEmailTesting(data);

        if (response.error) {
            message.error('Erro ao enviar e-mail');
            return;
        } else {
            message.success('E-mail enviado com sucesso!');
        }

        this.template = response.data;
        return response;
    }


    async editTemplate(data: any) {
        const response = await TemplateService.editTemplate(data, data.uuid);

        if (response.error) {
            message.error('Erro ao enviar e-mail');
            return;
        } else {
            message.success('E-mail enviado com sucesso!');
        }

        this.template = response.data;
        return response;
    }


    async getBodyEmailData(data: any) {
        this.loading = true;
        const response = await TemplateService.getBodyEmailData(data);

        if (response.error) {
            message.error('Erro ao buscar Produto');
            return;
        }

        this.loading = false;
        return response.data;
    }
}

const templateStore = new TemplateStore();
export default templateStore;