import { message } from 'antd';
import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import './styles.scss';

interface FileUploadProps {
    onFileProcessed: (data: any) => void; // Função que receberá os dados processados
    requiredColumns: string[]; // Colunas que devem estar presentes no arquivo
    title?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileProcessed, requiredColumns, title }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref para o input
    const [fileName, setFileName] = useState<string | null>(null);

    const processFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            // Lê os dados do arquivo
            //@ts-ignore
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            // Valida as colunas
            const validationError = validateColumns(jsonData, requiredColumns);
            if (validationError) {
                message.error(validationError);
                return; // Retorna se houver erro de validação
            }

            // Chama a função recebida com os dados processados
            onFileProcessed(jsonData);

            // Reseta o input após o processamento
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Limpa o valor do input
            }
        };
        reader.readAsArrayBuffer(file);

        //@ts-ignore
        document.getElementById('file_upload').value = '';
    };

    const validateColumns = (data: any[], requiredColumns: string[]): string | null => {
        if (data.length === 0) return 'O arquivo está vazio.';
        const fileColumns = Object.keys(data[0]);

        // Valida colunas faltantes
        for (const column of requiredColumns) {
            if (!fileColumns.includes(column)) {
                return `Coluna obrigatória "${column}" não encontrada.`;
            }
        }

        // Verifica colunas adicionais inesperadas
        const extraColumns = fileColumns.filter(col => !requiredColumns.includes(col));
        if (extraColumns.length > 0) {
            return `Colunas extras encontradas: ${extraColumns.join(', ')}. Remova-as e tente novamente.`;
        }

        return null;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            processFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            message.error(`Por favor, selecione um arquivo XLSX válido.`);
        }
    };

    return (
        <div>
            <input
                type="file"
                accept=".xlsx"
                id="file_upload"
                onChange={handleFileChange}
                ref={fileInputRef} // Adiciona a referência ao input
                style={{ display: 'none' }} // Oculta o input
            />
            <p onClick={() => fileInputRef.current?.click()} className="upload-button bg-primary-color">
                {fileName ? `Arquivo: ${fileName}` : title || 'Fazer Upload'}
            </p>
        </div>
    );
};

export default FileUpload;