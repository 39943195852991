import URLS from '../config/urls';
import BaseAPI from './BaseApi';

class AuthService {
  async login(code: string, data: any): Promise<any> {
    try { // Presumo que 'data' seja esse objeto
      const response = await BaseAPI.post(URLS.LOGIN, data, {
        headers: {
          code, // Certifique-se de que 'code' esteja definido em algum lugar
        },
      });
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Não autenticado" }; // Retorna um objeto de erro
    }
  }


  async authentication2fa(data: any): Promise<any> {
    try {
      const response = await BaseAPI.put(`${URLS.AUTHENTICATION}`, data);
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
    }
  }

  /**Faz requisição de redefinição de senha do email informado */
  async redefinePass(email: string): Promise<any> {
    try {
      const response = await BaseAPI.post(URLS.FORGET_PASSWORD, { email });
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }

  async validateToken(token: string): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.FORGET_PASSWORD}/${token}`);
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }

  async changePassword(email: string, token: string, newPassword: string): Promise<any> {
    try {
      const response = await BaseAPI.post(`${URLS.FORGET_PASSWORD}/update`, {
        email,
        token,
        newPassword,
      });
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }


  async logout(): Promise<void> {
    // Lógica para logout
  }

  async verifyTwoFactorAuth() {

  }

  // async getCurrentUser(): Promise<User | null> {
  //     // Lógica para obter o usuário atual
  // }
}

export default new AuthService();