export function mergeAuthorities(data: any) {
    const uniqueAuthorities = new Map();

    // Adiciona os authorities externos
    data.authorities.forEach((authority: any) => {
        // Clonamos o objeto e adicionamos a propriedade "source" como "external"
        const authorityWithSource = { ...authority, source: false };
        uniqueAuthorities.set(authority.uuid, authorityWithSource);
    });

    // Adiciona os authorities internos
    data.authorityGroups.forEach((group: any) => {
        group.authorities.forEach((authority: any) => {
            // Clonamos o objeto e adicionamos a propriedade "source" como "internal"
            const authorityWithSource = { ...authority, source: true };
            uniqueAuthorities.set(authority.uuid, authorityWithSource);
        });
    });

    // Retorna o array de authorities únicos, incluindo a origem
    return Array.from(uniqueAuthorities.values());
}