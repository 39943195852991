import { BellOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import MenuComponent from '../components/organisms/MenuComponent';
import { AuthProvider } from '../components/protected/ProviderAuth';
import authStore from '../stores/authStore';
import './styles.scss';

interface MainLayoutContentProps {
    setIsDarkMode: () => void;
}

const MainLayoutContent: React.FC<MainLayoutContentProps> = ({ setIsDarkMode }) => {
    const [collapsed, setCollapsed] = useState(false);
    const { account } = authStore;

    const items: MenuProps['items'] = [
        {
            key: '2',
            label: (
                <p onClick={() => authStore.logout()}>
                    Desconectar
                </p>
            ),
        },
    ];

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className="h-screen overflow-hidden flex">
            <div className="custom-menu">
                <MenuComponent collapsed={collapsed} />
            </div>

            <div className="inline-block w-3/4 align-top" style={{ width: collapsed ? 'calc(100% - 79px)' : 'calc(100% - 256px)' }}>
                <Header className="header border-b dark:bg-midnight dark:border-0">
                    <div className="flex gap-3 items-center" >
                        <div>
                            {collapsed ? <MenuUnfoldOutlined className="dark:text-white" onClick={toggleCollapsed} /> : <MenuFoldOutlined className="dark:text-white" onClick={toggleCollapsed} />}
                        </div>
                        <a target="_blank" rel="noopener noreferrer" href="https://stg.3coracoesconecta.com.br/"><h2 className="dark:text-white">Visitar Loja</h2></a>
                    </div>

                    <div className="flex gap-6 flex-row">
                        {/* <MoonOutlined className="dark:text-white" onClick={() => setIsDarkMode()} /> */}
                        {/* <BellOutlined className="dark:text-white" /> */}

                        <Dropdown className="flex items-center cursor-pointer" menu={{ items }} placement="bottomRight" arrow>
                            <div className="flex gap-2" onClick={(e) => e.preventDefault()}>
                                <img className='text-2xl' src="/assets/header-user.png" alt="user" />
                                <p className="dark:text-white">{account?.name}</p>
                            </div>
                        </Dropdown>
                    </div>
                </Header>

                <div className="box dark:bg-midnight">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

interface MainLayoutProps {
    setIsDarkMode: () => void;
}

const MainLayout: React.FC<MainLayoutProps> = ({ setIsDarkMode }) => {
    return (
        <AuthProvider>
            <MainLayoutContent setIsDarkMode={setIsDarkMode} />
        </AuthProvider>
    );
};

export default MainLayout;