import { DeleteOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import productStore from '../../../../stores/productStore';
import { getUniqueFilters } from '../../../../utils/functions';
import { getStatusColor } from '../../../../utils/statusColorMapper';
import ActionButton from '../../../atoms/ButtonComponent';
import ContentBox from '../../../molecules/ContentBox';
import ModalComponent from '../../../organisms/ModalComponent';
import DrawerUnits from './DrawerUnits';


interface DataType {
    valEnd: any;
    valStart: any;
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

interface TableUnitsProps {
    unitList?: any;
    modalProduct?: boolean;
}

const TableUnits: React.FC<TableUnitsProps> = observer(({ unitList, modalProduct }) => {
    const { product, loading } = productStore;
    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            filters: getUniqueFilters(unitList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
        },
        {
            title: 'Valor Base',
            dataIndex: 'baseQty',
            key: 'baseQty',
            filters: getUniqueFilters(unitList, 'baseQty'),
            onFilter: (value, record) => record.baseQty.indexOf(value as string) === 0,
        },
        {
            title: 'Multiplo Ativo',
            dataIndex: 'displayUnits',
            key: 'displayUnits',
            filters: getUniqueFilters(unitList, 'displayUnits'),
            onFilter: (value, record) => record.displayUnits.indexOf(value as string) === 0,
            render: (displayUnits) => {
                let text = displayUnits === 'HE' ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            hidden: modalProduct,
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        productStore.removeUnit(itemSelected.uuid);
        setIsModalOpen(false);
    };


    return (
        <div>

            {!modalProduct &&
                <div className="flex justify-between items-center mb-4">
                    <div className="flex flex-col">
                        <h1 className="text-lg font-semibold">Cadastro de Unidade</h1>
                        <p className="text-gray-500 text-sm">Tela para cadastro de Unidades</p>
                    </div>
                    <div className="flex gap-2">
                        <ActionButton actionType="create" onClick={() => setOpenModalRegister(true)}>Cadastrar</ActionButton>
                    </div>
                </div>
            }


            <Table
                className="table-custom"
                columns={columns}
                dataSource={unitList?.filter((item: any) => item.deleted === false).map((item: any, index: number) => ({
                    ...item,
                    key: item.uuid || index,
                }))}
                loading={loading}
                size="middle"
                bordered={false}
                pagination={false}
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Unidade <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <DrawerUnits isModalOpen={openModalRegister} setIsModalOpen={setOpenModalRegister} />
        </div>
    );
});

export default TableUnits;