import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ContentBox from '../../../molecules/ContentBox';
import categoryStore from '../../../../stores/categoryStore';
import productStore from '../../../../stores/productStore';
import { useParams } from 'react-router-dom';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';


const CategorySelected: React.FC = observer(() => {
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>();
    const { categoryListSelect, categoryProps } = categoryStore;
    const { product } = productStore;
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const { uuid } = useParams<{ uuid: string }>();

    useEffect(() => {
        if (product && product.categories) {
            const uuids = categoryProps?.parentCategory?.uuid;

            setCheckedKeys([uuids]);
            const parentKeys = findParentKeys(uuids);
            setExpandedKeys(parentKeys);
        }
    }, [product]);


    const onCheck = (checkedKeysValue: any, info: any) => {
        const selectedKey = info.node.key as React.Key;
        //@ts-ignore
        const isSelected = checkedKeysValue?.checked.includes(selectedKey);

        if (!isSelected) {
            setCheckedKeys([]);
        } else {
            setCheckedKeys([selectedKey]);
            categoryStore.saveCategoryTree(selectedKey);
        }
    };

    // Função para encontrar chaves ancestrais
    const findParentKeys = (key: React.Key) => {
        const parents: React.Key[] = [];
        const findParent = (data: any, currentKey: React.Key) => {
            for (const item of data) {
                if (item.key === currentKey) {
                    parents.push(item.key);
                    return true;
                }
                if (item.children) {
                    if (findParent(item.children, currentKey)) {
                        parents.push(item.key);
                        return true;
                    }
                }
            }
            return false;
        };
        findParent(categoryListSelect, key);
        return parents;
    };


    const isNodeDisabled = (node: any) => {
        return node.uuid === uuid;
    };

    // Função para criar o treeData com base nos dados e desabilitação
    const generateTreeData = (data: any, parentDisabled = false) => {
        return data.map((item: any) => {
            const isDisabled = parentDisabled || isNodeDisabled(item);

            return {
                ...item,
                disabled: isDisabled,
                children: item.children ? generateTreeData(item.children, isDisabled) : [],
            };
        });
    };

    const treeData = generateTreeData(categoryListSelect);

    return (
        <div>
            <ContentBox className="mb-4 p-6 rounded-none" title="Seleção de Categoria" description='Selecione uma Categoria'>
                <Tree
                    checkable
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    treeData={treeData}
                    checkStrictly
                />
            </ContentBox>
        </div>
    );
});

export default CategorySelected;