import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, message, Space, Table, Tag, Tooltip } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR_TOOLTIP, DEFAULT_PAGE_SIZE, defaultparamsExport, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionAdministrator } from '../../../permissions/PermissionAdministrator';
import administratorStore from '../../../stores/administratorStore';
import { AdministratorModel } from '../../../types/Administrator';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters, getUniquePropertyFilters } from '../../../utils/functions';
import { StatusActive } from '../../../utils/selects';
import { getStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';
import { UserModel } from '../../../types/User';
import PermissionComponent from '../../atoms/PermissionComponent';

interface DataType {
    active: boolean | React.Key;
    uuid: string;
    user: UserModel;
    code: string;
    key: React.Key;
    name: string;
    responsive: boolean;
    tag: string;
}

interface QueryParams {
    active: boolean;
    email: string;
    username: string;
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Administradores</p>,
    },
]

const ListAdministrator: React.FC = observer(() => {
    const { administratorList, loading, page, totalElements, defaultparams, sort, size } = administratorStore;
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useQueryParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<UserModel>();
    const listRouter = () => { navigate(`/administradores/cadastro`); }
    const [selectionCheckbox, setSelectionCheckbox] = useState<any[]>([]);
    const { hasPermission, isColumnVisible } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        name: 'name',
        user_username: 'user_username',
        user_email: 'user_email',
        active: 'active',
    };


    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionAdministrator.GET)) {
                administratorStore.getList(params);
            }
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: getUniqueFilters(administratorList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
        },
        {
            title: 'Usuário',
            dataIndex: 'user',
            key: 'user.username',
            sorter: true,
            filters: getUniquePropertyFilters<any, 'user', 'username'>(administratorList, 'user', 'username'),
            onFilter: (value, record) => record.user.username.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('user.username', queryParams.sort),
            render: (item) => (
                <Tooltip color={COLOR_TOOLTIP} title={item?.username}>
                    <div className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.username}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'user.email',
            filters: getUniquePropertyFilters<any, 'user', 'email'>(administratorList, 'user', 'email'),
            onFilter: (value, record) => record.user.username.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('user.email', queryParams.sort),
            sorter: true,
            render: (item) => (
                <Tooltip color={COLOR_TOOLTIP} title={item?.email}>
                    <div title={item?.email} className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.email}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Ativos',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            defaultSortOrder: getSortOrder('active', queryParams.sort),
            render: (active) => {
                let text = active ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionAdministrator.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/administradores/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionAdministrator.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const initialValues = {
        name: queryParams.name || '',
        username: queryParams.user_username || '',
        email: queryParams.user_email || '',
        active: typeof queryParams.active === 'string'
            ? { value: queryParams.active, label: queryParams.active }
            : { value: '', label: '' },
    };

    const handleSubmit = async (values: { name: string; username: string; email: string; active: { value: string; label: string; } }) => {
        const newParams: any = {
            name: values.name || undefined,
            user_username: values.username || undefined,
            user_email: values.email || undefined,
            active: values.active.value || undefined,
        };

        const search = [
            values.name ? `name:"${values.name}"` : null,
            values.username ? `user_username:"${values.username}"` : null,
            values.email ? `user_email:"${values.email}"` : null,
            values.active?.value ? `active:"${values.active.value}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort || 'created,desc',
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await administratorStore.getList(params);
    };

    // Faz o filtro da tabela
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await administratorStore.getList(defaultparams);
    };


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = async () => {
        if (itemSelected?.uuid !== undefined) {
            const response = await administratorStore.deleteAdministrator(itemSelected.uuid);
            if (!response?.error) {
                await administratorStore.getList(defaultparams);
                setIsModalOpen(false);
            }
        }
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        //@ts-ignore
        const params = buildParams(pagination, sorter, queryParams);
        await administratorStore.getList(params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = buildParamsFromQuery(queryParams, mappings);

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort,
            search: filters.search || queryParams.search || '',
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };


    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };


    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => administratorStore.deleteAdministratorAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    message.error('Erro ao deletar um ou mais Administradores.');
                    setSelectionCheckbox([]);
                } else {
                    message.success('Todos os Administradores foram deletados com sucesso!');
                    setSelectionCheckbox([]);
                    await administratorStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os Administradores.');
        }
    };

    const handleExport = async () => {
        const dataExport = await administratorStore.getList(defaultparamsExport);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: AdministratorModel) => ({
                name: item.name,
                username: item.user.username,
                email: item.user.email,
                created: formatDateString(item.created),
                active: item.active ? "Sim" : "Não",
            }));

            exportToExcel(listExport, ["Nome", "Usuário", "E-mail", "Data de Criação", "Ativo"], "Tabela de Administradores.xlsx");
        }
    };


    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionAdministrator.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionAdministrator.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-3 p-6" title="Administradores" description='Tela de listagem de Administradores'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (  // Passa o resetForm do render prop de Formik
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-3">
                                    <div className="w-full">
                                        <InputComponet label="Nome" name="name" id="name" />
                                    </div>
                                    <div className="w-full">
                                        <InputComponet label="Usuário" name="username" id="username" />
                                    </div>
                                    <div className="w-full">
                                        <InputComponet label="E-mail" name="email" id="email" />
                                    </div>
                                </div>

                                <div className="flex items-end w-full gap-4 pb-3 grid grid-cols-3">
                                    <div className="w-full">
                                        <SelectComponent label="Status" name="active" options={StatusActive} />
                                    </div>
                                </div>

                                <div className="flex items-end w-full gap-4 justify-end">
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" description='Tabela de Administradores' actions={
                    <>
                        <Permission permissionKeys={[PermissionAdministrator.DELETE]}>
                            <ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>
                                Deletar
                            </ActionButton>
                        </Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={administratorList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        rowSelection={rowSelection}
                        onChange={onChange}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Administrador <b>{itemSelected?.username}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />
        </>
    );
});

export default ListAdministrator;