import { EyeOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Space, Table, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { defaultparamsExport, DEFAULT_PAGE_SIZE } from '../../../config/config';
import useQueryParams, { buildParamsFromQueryReceivedParams } from '../../../hooks/useQueryParams';
import OrderModel from '../../../models/OrderModel';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';
import { PermissionOrder } from '../../../permissions/PermissionOrder';
import orderStore from '../../../stores/orderStore';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { formatCurrency, getSortField, getSortOrder, getUniquePropertyFilters, valueStatusConvert } from '../../../utils/functions';
import { getOrderStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalCliente from '../../organisms/ModalCliente';
import { useAuth } from '../../protected/ProviderAuth';
import PermissionComponent from '../../atoms/PermissionComponent';

interface DataType {
    status: any;
    totalBilled: any;
    total: any;
    paymentDetails: any;
    uuid: any;
    key: React.Key;
    idOrder: string;
    externalId: string;
    customer: any;
}

interface QueryParams {
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    status_statusName?: string;
    active?: string;
}

interface FormValues {
    idOrder: string;
    customer_firstName: string; // Ajuste para o Select
    paymentDetails_paymentType: { label: string; value: string }; // Ajuste para o Select
    status_statusName: { label: string; value: string }; // Ajuste para o Select
}

const paymentType = [
    {
        value: 'PIX',
        label: 'PIX',
    },
    {
        value: 'INVOICE',
        label: 'Boleto',
    },
    {
        value: 'CREDIT_CARD',
        label: 'Cartão de Crédito',
    },
];

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Pedidos</p>,
    },
]

export const orderStatus = [
    { id: 'all', value: 'all', label: 'Todos' },
    { id: '1', value: 'ANALYSIS', label: 'Pedido Realizado' },
    { id: '2', value: 'PAID', label: 'Pagamento Autorizado' },
    { id: '3', value: 'FINISHED', label: 'Pedido Concluído' },
    { id: '4', value: 'BILLED', label: 'Pedido Faturado' },
    { id: '5', value: 'SENT', label: 'Pedido Saiu Para Entrega' },
    { id: '6', value: 'DELIVERED', label: 'Pedido Entregue' },
    { id: '7', value: 'PENDING_PAYMENT', label: 'Pendente de pagamento' },
    { id: '8', value: 'RETURNED', label: 'Retornado' },
    { id: '9', value: 'CANCELED', label: 'Pedido Cancelado' },
];

const ListOrder: React.FC = observer(() => {
    const { orderList, loading, page, totalElements, size, defaultparams, sort } = orderStore;
    const [itemSelected, setItemSelected] = useState<any>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useQueryParams();
    const { hasPermission, isColumnVisible } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        idOrder: 'idOrder',
        customer_firstName: 'customer_firstName',
        paymentDetails_paymentType: 'paymentDetails_paymentType',
        status_statusName: 'status_statusName'
    };

    const paramsDefault = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: queryParams.status_statusName ? '' : 'status_statusName!CART',
    };

    useEffect(() => {
        const params = buildParamsFromQueryReceivedParams(queryParams, mappings, paramsDefault, ":", ',');
        const cleanedString = params?.search?.replace(/\s*([,:])\s*/g, '$1');
        params.search = cleanedString

        const fetchData = async () => {
            if (hasPermission(PermissionOrder.GET)) {
                await orderStore.getList(params);
            }
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Número',
            dataIndex: 'idOrder',
            key: 'idOrder',
            defaultSortOrder: getSortOrder('idOrder', queryParams.sort),
            sorter: true,
        },
        {
            title: 'SAP Número',
            dataIndex: 'externalId',
            key: 'externalId',
            defaultSortOrder: getSortOrder('externalId', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Cliente',
            dataIndex: 'customer', // Verifique a estrutura dos dados
            key: 'customer.firstName',
            filters: getUniquePropertyFilters<any, 'customer', 'firstName'>(orderList, 'customer', 'firstName'),
            onFilter: (value, record) => record.customer.firstName.indexOf(value as string) === 0, // Acesse o nome do canal
            sorter: true,
            defaultSortOrder: getSortOrder('customer.firstName', queryParams.sort),
            render: (item) => {
                return (
                    <>{item && <p>{item?.firstName}</p>}</>
                );
            },
        },
        {
            title: 'Tipo de Pagamento',
            dataIndex: 'paymentDetails',
            key: 'paymentDetails.paymentOptionName',
            filters: getUniquePropertyFilters<any, 'paymentDetails', 'paymentOptionName'>(orderList, 'paymentDetails', 'paymentOptionName'),
            onFilter: (value, record) => record.paymentDetails?.paymentOptionName.indexOf(value as string) === 0, // Acesse o nome do canal
            sorter: true,
            defaultSortOrder: getSortOrder('paymentDetails?.paymentOptionName', queryParams.sort),
            render: (item) => {
                return (
                    <>{item && <p>{item?.paymentOptionName}</p>}</>
                );
            },
        },
        {
            title: 'Valor Previo',
            dataIndex: 'total',
            key: 'total',
            defaultSortOrder: getSortOrder('total', queryParams.sort),
            sorter: true,
            render: (total) => {
                return (
                    <>{total && <p title={total}>{formatCurrency(total)}</p>}</>
                );
            },
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => <p>{formatDateString(text)}</p>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            defaultSortOrder: getSortOrder('status?.statusName', queryParams.sort),
            render: (status) => {
                return (
                    <Tag color={getOrderStatusColor(status.statusName)}>{valueStatusConvert(status?.statusName)}</Tag>
                );
            },
        },
        {
            title: 'Visualizar Pedido',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            width: 50,
            hidden: !isColumnVisible(PermissionOrder.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/detalhes-pedido/customer/${record?.customer?.uuid}/order/${record?.uuid}`}>
                        <div><EyeOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Visualizar Cliente',
            dataIndex: 'client',
            key: 'client',
            align: 'center',
            width: 50,
            hidden: !isColumnVisible(PermissionCustomer.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <div onClick={() => showModal(record)}>
                        <div><EyeOutlined /></div>
                    </div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const initialValues = {
        idOrder: Array.isArray(queryParams.idOrder) ? queryParams.idOrder[0] || '' : queryParams.idOrder || '',
        customer_firstName: Array.isArray(queryParams.customer_firstName) ? queryParams.customer_firstName[0] || '' : queryParams.customer_firstName || '',
        paymentDetails_paymentType: typeof queryParams.paymentDetails_paymentType === 'string'
            ? { value: queryParams.paymentDetails_paymentType, label: queryParams.paymentDetails_paymentType }
            : { value: '', label: '' },
        status_statusName: typeof queryParams.status_statusName === 'string'
            ? { value: queryParams.status_statusName, label: queryParams.status_statusName }
            : { value: '', label: '' },
    };

    const handleSubmit = async (values: FormValues) => {
        const newParams: any = {
            idOrder: values.idOrder || undefined,
            customer_firstName: values?.customer_firstName || undefined,
            paymentDetails_paymentType: values?.paymentDetails_paymentType?.value || undefined,
            status_statusName: values?.status_statusName?.value || undefined,
        };

        const search = [
            values.idOrder ? `,idOrder:"${values.idOrder}"` : null,
            values.customer_firstName ? `,customer_firstName:${values.customer_firstName}` : null,
            values.paymentDetails_paymentType?.value ? `,paymentDetails_paymentType;${values.paymentDetails_paymentType.value}` : null,
            values.status_statusName?.label ? `,status_statusName:"${values.status_statusName.value}"` : ',status_statusName!CART',
        ]
            .filter(Boolean)
            .join('');


        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort || 'created,desc',
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await orderStore.getList(params);
    };

    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await orderStore.getList(defaultparams);
    };

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams)
        await orderStore.getList(params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = buildParamsFromQueryReceivedParams(queryParams, mappings, paramsDefault, ":", ',');

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort,
            search: filters.search || queryParams.search || ',status_statusName!CART',
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };

    const handleExport = async () => {
        const dataExport = await orderStore.getList(defaultparamsExport);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: OrderModel) => ({
                idOrder: item.idOrder,
                externalId: item.externalId,
                customer: item.customer?.firstName,
                document: item?.customer?.document,
                paymentOptionName: item.paymentDetails?.paymentOptionName,
                source: item.source,
                total: item.total,
                created: formatDateString(item.created ?? ""),
                status: valueStatusConvert(item.status?.statusName ?? ""),
            }));

            exportToExcel(listExport, ["Número", 'SAP N°', "Cliente", 'CNPJ', "Tipo de Pagamento", 'Origem', "Valor Prévio", "Data de Criação", "Status"], "Tabela de Pedido.xlsx");
        }
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            <Permission permissionKeys={[PermissionOrder.GET]} fallback={<PermissionComponent />}>
                <ContentBox title="Pedidos" description='Tela de listagem de Pedidos' className="mb-4 p-6">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-4">
                                    <InputComponet label="Número" name="idOrder" id="idOrder" />
                                    <InputComponet label="Cliente" name="customer_firstName" id="customer_firstName" />
                                    <SelectComponent label="Status" name="status_statusName" options={orderStatus} />
                                </div>

                                <div className="grid grid-cols-12 gap-6 mb-2" >
                                    <div className="col-span-4 mb-4">
                                        <SelectComponent label="Tipo de pagamento" name="paymentDetails_paymentType" options={paymentType} />
                                    </div>
                                </div>

                                <div className="flex items-end gap-4 justify-end">
                                    <Button onClick={() => handleClearAndSearch(resetForm)}>
                                        Limpar
                                    </Button>
                                    <Button htmlType="submit" type="primary">Filtrar</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>
                <ContentBox className="mb-4 p-6" description='Tabela de Pedidos' actions={
                    <>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        columns={columns}
                        className="table-custom"
                        dataSource={orderList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        size="middle"
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            <ModalCliente notViewClientUnitsActive setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} customer={itemSelected?.customer} />
        </>
    );
});

export default ListOrder;