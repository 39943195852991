import { Button, Drawer } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import productStore from '../../../../../stores/productStore';
import { MultipleRule } from '../../../../../utils/selects';
import InputComponent from '../../../../atoms/InputComponet';
import InputNumberComponente from '../../../../atoms/InputNumberComponet';
import SelectComponent from '../../../../atoms/SelectComponent';
import { validationSchema } from './schema';



interface DrawerUnitsProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerUnits: React.FC<DrawerUnitsProps> = ({ isModalOpen, setIsModalOpen }) => {
    const handleSubmit = async (values: any) => {
        const createProps = {
            active: true,
            ...values,
            displayUnits: values.displayUnits.value,
            uuid: uuidv4(),
            deleted: false,
            new: true,
        };

        const response = await productStore.addUnit(createProps);
        return response;
    };

    const initialValuesForm = {
        name: '',
        baseQty: '',
        displayUnits: '',
    };

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValuesForm} onSubmit={async (values, { resetForm }) => {
            const response = await handleSubmit(values);
            if (response === 'Sucess') {
                resetForm();
                setIsModalOpen(false);
            }
        }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastrar Unidade"
                    width={540}
                    onClose={() => {
                        setIsModalOpen(false);
                        resetForm();
                    }}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}>
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex gap-4 flex-col">
                            <InputComponent size="middle" label="Nome" name="name" id="name" />
                            <InputNumberComponente size="middle" label="Valor Base" name="baseQty" id="baseQty" />
                            <SelectComponent size="middle" label="Multiplo" name="displayUnits" options={MultipleRule} />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
};

export default DrawerUnits;
