import type { TableColumnsType, TableProps } from 'antd';
import { Button, Table } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../config/config';
import useQueryParams from '../../../hooks/useQueryParams';
import { PermissionReports } from '../../../permissions/PermissionReports';
import reportLogAcessStore from '../../../stores/reportLogAcessStore';
import { LogAcess } from '../../../types/LogAcess';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { convertToDayjs, formattedDates, getFirstFilterDates, getSortOrder, getUniqueFilters, getUniquePropertyFilters } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import DatePickerComponet from '../../atoms/DatePickerComponet';
import InputComponent from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    ip: any;
    user: any;
    customer: any;
    document: string;
    type: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    to: string;
    from: string;
    size?: number;
    page?: number;
    labelTerm?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Relatório de Log de Acesso</p>,
    },
]

const ReportLogAcess: React.FC = observer(() => {
    const { reportList, totalElements, sort, loading, page, size } = reportLogAcessStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const [initialDate, finalDate] = getFirstFilterDates();
    const { hasPermission } = useAuth();

    const initialValues = {
        date: queryParams.from && queryParams.to ? [convertToDayjs(queryParams.from), convertToDayjs(queryParams.to)] : [convertToDayjs(initialDate), convertToDayjs(finalDate)], // Define o valor como um array com objetos moment
        searchTerm: typeof queryParams.searchTerm === 'string'
            ? { value: queryParams.searchTerm, label: queryParams.labelTerm }
            : { value: '', label: '' },
    };

    useEffect(() => {
        const initialParams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            customer: queryParams.labelTerm || undefined,
            from: queryParams.from ? queryParams.from : initialDate,
            to: queryParams.to ? queryParams.to : finalDate,
            sort: "created",
            page: queryParams.page || "0",
            size: queryParams.size || DEFAULT_PAGE_SIZE
        }

        const fetchData = async () => {
            if (hasPermission(PermissionReports.LOGINS)) {
                reportLogAcessStore.getList(initialParams);
            }
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Data de Login',
            dataIndex: 'created',
            key: 'created',
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Usuário',
            dataIndex: 'user', // Verifique a estrutura dos dados
            key: 'user.username',
            filters: getUniquePropertyFilters<any, 'user', 'username'>(reportList, 'user', 'username'),
            onFilter: (value, record) => record.user.username.indexOf(value as string) === 0, // Acesse o nome do canal
            defaultSortOrder: getSortOrder('user.username', queryParams.sort),
            render: (item) => {
                return (
                    <>{item && <p>{item?.username}</p>}</>
                );
            },
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            filters: getUniqueFilters(reportList, 'ip'),
            onFilter: (value, record) => record.ip.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('ip', queryParams.sort),
            sorter: true,
        },
    ];


    // Faz o filtro da tabela
    const handleSubmit = async (values: { customer: string; date: any }) => {
        const formattedDate = formattedDates(values.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];

        const params = {
            customer: values.customer || "",
            from: dateInitial,
            to: dateFinal,
            sort: queryParams.sort || sort,
            page: queryParams.page || "0",
            size: queryParams.size || DEFAULT_PAGE_SIZE
        }

        const newParams: any = {
            customer: values.customer || undefined,
            to: dateFinal || undefined,
            from: dateInitial || undefined,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await reportLogAcessStore.getList(params);
    };


    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL

        const clearparams = {
            "customer": '',
            "from": initialDate,
            "to": finalDate,
            "sort": "created",
            "page": "0",
            "size": DEFAULT_PAGE_SIZE
        }

        await reportLogAcessStore.getList(clearparams);
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        //@ts-ignore
        const params = buildParams(pagination, queryParams);
        await reportLogAcessStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        queryParams: QueryParams
    ) => {
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const from = initialDate;
        const to = finalDate

        const queryFrom = queryParams.from
        const queryTo = queryParams.to


        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            "customer": queryParams.labelTerm || undefined,
            "from": queryParams.from === undefined ? from : queryFrom,
            "to": queryParams.to === undefined ? to : queryTo,
            "sort": "created"
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            customer: params.customer,
            from: queryParams.from,
            to: queryParams.to,
            sort: params.sort,
        });

        return params;
    };

    const handleExport = async () => {
        const defaultParams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            customer: queryParams.labelTerm || undefined,
            from: queryParams.from ? queryParams.from : initialDate,
            to: queryParams.to ? queryParams.to : finalDate,
            size: 9999,
            page: 0,
            sort: "created"
        };

        const dataExport = await reportLogAcessStore.getList(defaultParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: LogAcess) => ({
                created: formatDateString(item.created?.toString() ?? ""),
                username: item.user.username,
                ip: item.ip,
            }));

            exportToExcel(listExport, ["Data de Login", "Usuário", "IP"], "Tabela de Relatório de Log de Acesso.xlsx");
        }
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            <Permission permissionKeys={[PermissionReports.SALES_GENERAL]} fallback={NotPermissionComponent()}>
                <ContentBox className="mb-4 p-6" title="Log de Acesso" description='Informações sobre login de usuário'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (  // Passa o resetForm do render prop de Formik
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <InputComponent label="Usuario" name="customer" id="customer" />
                                    </div>
                                    <div className="w-full">
                                        <DatePickerComponet label="Data inicial" name="date" />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6">
                    <div className="flex items-end w-full gap-4 pb-4 items-center justify-between">
                        <p>Relatório de Log de Acesso</p>

                        <div className="flex gap-4">
                            <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                        </div>
                    </div>

                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={reportList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        onChange={onChange}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>
        </>
    );
});

export default ReportLogAcess;