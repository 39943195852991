import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ClientDetails from './components/pages/ClientDetails';
import CreateAdministrator from './components/pages/CreateAdministrator';
import CreateBanners from './components/pages/CreateBanners';
import CreateCampaing from './components/pages/CreateCampaing';
import CreateCategory from './components/pages/CreateCategory';
import CreateChannel from './components/pages/CreateChannel';
import CreateColor from './components/pages/CreateColor';
import CreateComponents from './components/pages/CreateComponents';
import CreateConfiguration from './components/pages/CreateConfiguration';
import CreateCupom from './components/pages/CreateCupom';
import CreateCustomer from './components/pages/CreateCustomer';
import CreateHome from './components/pages/CreateHome';
import CreateInstallment from './components/pages/CreateInstallment';
import CreateInstitucional from './components/pages/CreateInstitucional';
import CreateMaterial from './components/pages/CreateMaterial';
import CreatePerfil from './components/pages/CreatePerfil';
import CreateProduct from './components/pages/CreateProduct';
import CreateProductVariation from './components/pages/CreateProductVariation';
import CreateProperties from './components/pages/CreateProperties';
import CreateSelos from './components/pages/CreateSelos';
import CreateShipping from './components/pages/CreateShipping';
import CreateShippingUpload from './components/pages/CreateShippingUpload';
import CreateSize from './components/pages/CreateSize';
import CreateSuChannel from './components/pages/CreateSubChannel';
import CreateTemplateEmail from './components/pages/CreateTemplateEmail';
import CreateVoltagem from './components/pages/CreateVoltagem';
import Dashboard from './components/pages/Dashboard';
import ListAdministrator from './components/pages/ListAdministrator';
import ListBanner from './components/pages/ListBanner';
import ListCampaing from './components/pages/ListCampaing';
import ListCategory from './components/pages/ListCategory';
import ListChannel from './components/pages/ListChannel';
import ListColor from './components/pages/ListColor';
import ListComponents from './components/pages/ListComponents';
import ListCupom from './components/pages/ListCupom';
import ListCustomer from './components/pages/ListCustomer';
import ListEvaluations from './components/pages/ListEvaluations';
import ListHome from './components/pages/ListHome';
import ListInstitucional from './components/pages/ListInstitucional';
import ListLog from './components/pages/ListLog';
import ListMaterial from './components/pages/ListMaterial';
import ListOrder from './components/pages/ListOrder';
import ListPerfil from './components/pages/ListPerfil';
import ListProduct from './components/pages/ListProduct';
import ListProductByCategory from './components/pages/ListProductByCategory';
import ListProductVariation from './components/pages/ListProductVariations';
import ListProperties from './components/pages/ListProperties';
import ListSelos from './components/pages/ListSelos';
import ListShipping from './components/pages/ListShipping';
import ListSize from './components/pages/ListSize';
import ListSubChannel from './components/pages/ListSubChannel';
import ListTemplate from './components/pages/ListTemplate';
import ListVoltage from './components/pages/ListVoltage';
import LoginPage from './components/pages/LoginPage';
import OrderDetails from './components/pages/OrderDetails';
import PageNotFound from './components/pages/PageNotFound';
import ReportCampaings from './components/pages/ReportCampaings';
import ReportCartAbandonment from './components/pages/ReportCartAbandonment';
import ReportCupom from './components/pages/ReportCupom';
import ReportEvaluations from './components/pages/ReportEvaluations';
import ReportGeneralSales from './components/pages/ReportGeneralSales';
import ReportLogAcess from './components/pages/ReportLogAcess';
import ReportMostPurchasedProduct from './components/pages/ReportMostPurchasedProduct';
import ReportSalesByCategory from './components/pages/ReportSalesByCategory';
import ProtectedRoute from './components/protected/ProtectedRoute';
import { StoreProvider } from './context/StoreContext';
import MainLayout from './layouts/MainLayout';
import darkTheme from './themes/darktheme';
import lightTheme from './themes/lightTheme';

const authToken = Cookies.get('auth_token');
const isAuthenticated = !!authToken;

// const theme = {
//   token: { colorPrimary: '#7e7e7e' }, // Cor global escura, aplicada ao botão
// };

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark', !isDarkMode);
  };

  return (
    <StoreProvider>
      <ConfigProvider locale={ptBR} theme={isDarkMode ? darkTheme : lightTheme}>
        <Router>
          <Routes>
            {/* Rota de login pública */}
            <Route path="/login" element={<LoginPage />} />

            {/* Rotas protegidas dentro do layout principal */}
            <Route
              path="/"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <MainLayout setIsDarkMode={toggleTheme} />
                </ProtectedRoute>
              }
            >
              <Route path="dashboard" element={<Dashboard />} />

              {/* Default */}
              <Route path="" element={<Dashboard />} />

              {/* Home */}
              <Route path="cliente" element={<ListCustomer />} />
              <Route path="cliente/cadastro" element={<CreateCustomer />} />
              <Route path="cliente/editar/:uuid" element={<CreateCustomer />} />
              <Route path="cliente/detalhes/:uuid" element={<ClientDetails />} />

              {/* Home */}
              <Route path="home" element={<ListHome />} />
              <Route path="home/cadastro" element={<CreateHome />} />
              <Route path="home/editar/:uuid" element={<CreateHome />} />

              {/* Configuração */}
              <Route path="configuracao" element={<CreateConfiguration />} />

              {/* Campanhas */}
              <Route path="campanhas" element={<ListCampaing />} />
              <Route path="campanha/cadastro" element={<CreateCampaing />} />
              <Route path="campanha/editar/:uuid" element={<CreateCampaing />} />

              {/* Cupons */}
              <Route path="cupons" element={<ListCupom />} />
              <Route path="cupon/cadastro" element={<CreateCupom />} />
              <Route path="cupon/editar/:uuid" element={<CreateCupom />} />

              {/* Administradores */}
              <Route path="administradores" element={<ListAdministrator />} />
              <Route path="administradores/cadastro" element={<CreateAdministrator />} />
              <Route path="administradores/editar/:uuid" element={<CreateAdministrator />} />

              {/* Pedidos */}
              <Route path="pedidos" element={<ListOrder />} />
              <Route path="detalhes-pedido/customer/:customerUuid/order/:orderUuid" element={<OrderDetails />} />

              {/* Perfis */}
              <Route path="perfis" element={<ListPerfil />} />
              <Route path="perfis/cadastro" element={<CreatePerfil />} />
              <Route path="perfis/editar/:uuid" element={<CreatePerfil />} />

              {/* Produto Modelo */}
              <Route path="produtos" element={<ListProduct />} />
              <Route path="produto/cadastro" element={<CreateProduct />} />
              <Route path="produto/editar/:uuid" element={<CreateProduct />} />
              <Route path="produtos/categorias" element={<ListProductByCategory />} />




              {/* Produto Variação */}
              <Route path="produtos-variacao" element={<ListProductVariation />} />
              <Route path="produtos-variacao/cadastro" element={<CreateProductVariation />} />
              <Route path="produtos-variacao/editar/:uuid" element={<CreateProductVariation />} />

              {/* Categorias */}
              <Route path="categorias" element={<ListCategory />} />
              <Route path="categoria/cadastro" element={<CreateCategory />} />
              <Route path="categoria/editar/:uuid" element={<CreateCategory />} />

              {/* Canal */}
              <Route path="canal" element={<ListChannel />} />
              <Route path="canal/create" element={<CreateChannel />} />
              <Route path="canal/create/:uuid" element={<CreateChannel />} />

              {/* Material */}
              <Route path="material" element={<ListMaterial />} />
              <Route path="material/create" element={<CreateMaterial />} />
              <Route path="material/create/:uuid" element={<CreateMaterial />} />

              {/* Voltagem */}
              <Route path="voltagem" element={<ListVoltage />} />
              <Route path="voltagem/create" element={<CreateVoltagem />} />
              <Route path="voltagem/create/:uuid" element={<CreateVoltagem />} />

              {/* Tamanho */}
              <Route path="tamanhos" element={<ListSize />} />
              <Route path="tamanho/create" element={<CreateSize />} />
              <Route path="tamanho/create/:uuid" element={<CreateSize />} />

              {/* Cores */}
              <Route path="cores" element={<ListColor />} />
              <Route path="cores/create" element={<CreateColor />} />
              <Route path="cores/create/:uuid" element={<CreateColor />} />

              {/* Template Email */}
              <Route path="template" element={<ListTemplate />} />
              <Route path="template/editar/:uuid" element={<CreateTemplateEmail />} />

              {/* SubCanal */}
              <Route path="subcanal" element={<ListSubChannel />} />
              <Route path="subcanal/create" element={<CreateSuChannel />} />
              <Route path="subcanal/create/:uuid" element={<CreateSuChannel />} />

              {/* Frete */}
              <Route path="fretes" element={<ListShipping />} />
              <Route path="frete/create/" element={<CreateShipping />} />
              <Route path="frete/create/:uuid" element={<CreateShipping />} />
              <Route path="upload-frete" element={<CreateShippingUpload />} />

              {/* Avaliações */}
              <Route path="avaliacoes" element={<ListEvaluations />} />

              {/* Institucional */}
              <Route path="institucional" element={<ListInstitucional />} />
              <Route path="institucional/cadastro" element={<CreateInstitucional />} />
              <Route path="institucional/editar/:uuid" element={<CreateInstitucional />} />

              {/* Selos */}
              <Route path="selos" element={<ListSelos />} />
              <Route path="selo/create" element={<CreateSelos />} />

              {/* Especificação */}
              <Route path="especificacao" element={<ListProperties />} />
              <Route path="especificacao/cadastro" element={<CreateProperties />} />
              <Route path="especificacao/editar/:uuid" element={<CreateProperties />} />

              {/* Relatorios */}
              <Route path="visao-geral" element={<ReportGeneralSales />} />
              <Route path="relatorio-carrinho" element={<ReportCartAbandonment />} />
              <Route path="relatorio-produtos-mais-comprados" element={<ReportMostPurchasedProduct />} />
              <Route path="vendas-por-categoria" element={<ReportSalesByCategory />} />
              <Route path="relatorio-campanhas" element={<ReportCampaings />} />
              <Route path="relatorio-cupons" element={<ReportCupom />} />
              <Route path="log-de-acesso" element={<ReportLogAcess />} />
              <Route path="relatorio-avaliacoes" element={<ReportEvaluations />} />

              {/* Relatorios */}
              <Route path="banners" element={<ListBanner />} />
              <Route path="banner/cadastro" element={<CreateBanners />} />
              <Route path="banner/editar/:uuid" element={<CreateBanners />} />

              {/* Pagamento */}
              <Route path="parcelas" element={<CreateInstallment />} />

              {/* Componentes */}
              <Route path="componentes" element={<ListComponents />} />
              <Route path="componentes/cadastro" element={<CreateComponents />} />
              <Route path="componentes/editar/:uuid" element={<CreateComponents />} />


              {/* Log */}
              {/* <Route path="log-sistema" element={<ListLog />} /> */}


              {/* Rota para página não encontrada */}
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </Router>
      </ConfigProvider>
    </StoreProvider>
  );
}

export default App;

