import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { message, Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, defaultparamsExport, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionVoltage } from '../../../permissions/PermissionVoltage';
import voltagemStore from '../../../stores/voltagemStore';
import { Voltage } from '../../../types/Voltage';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';
import PermissionComponent from '../../atoms/PermissionComponent';

interface DataType {
    description: string;
    code: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Voltagem</p>,
    },
]

const ListSize: React.FC = observer(() => {
    const { voltageList, loading, page, totalElements, defaultparams, sort, size } = voltagemStore;
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const { hasPermission, isColumnVisible } = useAuth();
    const listRouter = () => { navigate(`/voltagem/create`); }

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionVoltage.GET)) {
                voltagemStore.getList(params);
            }
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
            filters: getUniqueFilters(voltageList, 'code'),
            onFilter: (value, record) => record.code.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('code', queryParams.sort),
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            filters: getUniqueFilters(voltageList, 'description'),
            onFilter: (value, record) => record.description.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('description', queryParams.sort),
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionVoltage.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/voltagem/create/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionVoltage.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    const handleDeleteItem = async () => {
        const response = await voltagemStore.deleteVoltagem(itemSelected.uuid);

        if (!response?.error) {
            voltagemStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await voltagemStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort, // Use o sort da tabela se disponível
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
        });

        return params;
    };


    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => voltagemStore.deleteVoltagemAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    setSelectionCheckbox([]);
                    message.error('Erro ao deletar um ou mais canais.');
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos os canais foram deletados com sucesso!');
                    await voltagemStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os canais.');
        }
    };

    const handleExport = async () => {
        const dataExport = await voltagemStore.getList(defaultparamsExport);

        if (dataExport) {
            const listExport = dataExport.content.map((item: Voltage) => ({
                code: item.code,
                description: item.description,
            }));

            exportToExcel(listExport, ["Código", "Descrição"], "Tabela de Voltagem.xlsx");
        }
    };


    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionVoltage.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />
            <Permission permissionKeys={[PermissionVoltage.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Voltagem" description='Tela de listagem de Voltagem' actions={
                    <>
                        <Permission permissionKeys={[PermissionVoltage.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>

                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={voltageList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>
            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Voltagem <b>{itemSelected?.code}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />
        </>
    );
});

export default ListSize;