import type { TableColumnsType, TableProps } from 'antd';
import { Button, Rate, Table } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../config/config';
import useQueryParams from '../../../hooks/useQueryParams';
import { PermissionReports } from '../../../permissions/PermissionReports';
import productStore from '../../../stores/productStore';
import reportEvaluationsStore from '../../../stores/reportEvaluationsStore';
import { RatingsReport } from '../../../types/Ratings';
import { exportToExcel } from '../../../utils/excelFunction';
import { convertToDayjs, formattedDates, getFirstFilterDates, getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import { ratingSelect } from '../../../utils/selects';
import AutocompleteComponent from '../../atoms/AutocompleteComponent';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import DatePickerComponet from '../../atoms/DatePickerComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { PermissionProducts } from '../../../permissions/PermissionProducts';

interface DataType {
  rating: any;
  qty: any;
  customer: any;
  document: string;
  type: string;
  name: string;
  uuid: string;
  key: React.Key;
}

interface QueryParams {
  to: string;
  from: string;
  size?: number;
  page?: number;
  sort?: string;
  labelTerm?: string;
}

// BreadCrumb
const breadCrumb = [
  {
    title: <a href="/dashboard">Dashboard</a>,
  },
  {
    title: <p>Relatório de Avaliações por Produto</p>,
  },
]

const ReportEvaluations: React.FC = observer(() => {
  const { reportList, loading, page, totalElements, sort, size } = reportEvaluationsStore;
  const [queryParams, setQueryParams] = useQueryParams();
  const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
  const [initialDate, finalDate] = getFirstFilterDates();
  const { hasPermission } = useAuth();

  const initialValues = {
    date: queryParams.from && queryParams.to ? [convertToDayjs(queryParams.from), convertToDayjs(queryParams.to)] : [convertToDayjs(initialDate), convertToDayjs(finalDate)], // Define o valor como um array com objetos moment
    searchTerm: typeof queryParams.searchTerm === 'string'
      ? { value: queryParams.searchTerm, label: queryParams.labelTerm }
      : { value: '', label: '' },
    rating: typeof queryParams.searchTerm === 'string'
      ? { value: queryParams.searchTerm, label: queryParams.labelTerm }
      : { value: '5', label: '' },
  };

  const initialParams = {
    productName: queryParams.labelTerm || '',
    rating: queryParams.rating || 5,
    dataInicio: queryParams.from ? queryParams.from : initialDate,
    dataFim: queryParams.to ? queryParams.to : finalDate,
    sort: "name",
    page: queryParams.page || "0",
    size: queryParams.size || DEFAULT_PAGE_SIZE
  }

  useEffect(() => {
    const fetchData = async () => {
      if (hasPermission(PermissionReports.RATINGS_BY_PRODUCTS)) {
        reportEvaluationsStore.getList(initialParams);
      }
    };

    fetchData();
  }, []);


  // Colunas da Tabela
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      filters: getUniqueFilters(reportList, 'name'),
      onFilter: (value, record) => record.name.indexOf(value as string) === 0,
      defaultSortOrder: getSortOrder('name', queryParams.sort),
    },
    {
      title: 'Total de Avaliações',
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      filters: getUniqueFilters(reportList, 'qty'),
      onFilter: (value, record) => record.qty.indexOf(value as string) === 0,
      defaultSortOrder: getSortOrder('qty', queryParams.sort),
    },
    {
      title: 'Avaliação',
      dataIndex: 'rating',
      key: 'rating',
      sorter: true,
      filters: getUniqueFilters(reportList, 'rating'),
      onFilter: (value, record) => record.rating === value,
      defaultSortOrder: getSortOrder('rating', queryParams.sort),
      render: (text) => {
        return (
          <>{<Rate className="rating" disabled={true} value={text} />}</>
        );
      },
    },
  ];


  // Faz o filtro da tabela
  const handleSubmit = async (values: { searchTerm: { value: string; label: string }; date: any; rating: { value: string; label: string } }) => {
    const formattedDate = formattedDates(values.date, 'YYYY-MM-DD');
    const dateInitial = formattedDate[0];
    const dateFinal = formattedDate[1];

    const params = {
      productName: values.searchTerm.label || "",
      dataInicio: dateInitial,
      dataFim: dateFinal,
      sort: queryParams.sort || sort || 'name',
      page: queryParams.page || "0",
      size: queryParams.size || DEFAULT_PAGE_SIZE,
      rating: values.rating.value || "5"
    }

    const newParams: any = {
      searchTerm: values.searchTerm.value || undefined,
      labelTerm: values.searchTerm.label || undefined,
      dataFim: dateFinal || undefined,
      dataInicio: dateInitial || undefined,
      rating: values.rating.value || "5"
    };

    setQueryParams(newParams);

    //@ts-ignore
    await reportEvaluationsStore.getList(params);
  };


  // Limpa os dados do formulario
  const handleClearAndSearch = async (resetForm: () => void) => {
    resetForm(); // Limpa os valores do formulário
    setQueryParams({}); // Limpa a URL
    setAutocompleteOptions([]);

    const clearparams = {
      productName: '',
      dataInicio: initialDate,
      dataFim: finalDate,
      sort: "created",
      page: "0",
      size: DEFAULT_PAGE_SIZE,
      rating: "5"
    }

    await reportEvaluationsStore.getList(clearparams);
  };


  const handleSearch = async (searchText: string) => {
    if (searchText.length > 3) {
      try {
        const response = await productStore.searchProduct(searchText);
        setAutocompleteOptions(response);
        // Aqui você pode mapear a resposta para opções, se necessário
      } catch (error) {
        console.error('Erro ao buscar cliente:', error);
      }
    }
  };


  const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
    //@ts-ignore
    const params = buildParams(pagination, sorter, queryParams)
    await reportEvaluationsStore.getList(params);
  };

  const buildParams = (
    pagination: any,
    sorter: any,
    queryParams: QueryParams
  ) => {
    const getSort = getSortField(sorter);
    const currentPage = pagination.current - 1;
    const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

    const params = {
      size: pagination.pageSize || queryParams.size || newPageSize,
      page: pagination.current ? currentPage : queryParams.page || 0,
      productName: queryParams.labelTerm || undefined,
      dataInicio: queryParams.from,
      dataFim: queryParams.to,
      sort: getSort || queryParams.sort || sort,
    };

    // Atualiza a URL com os novos parâmetros
    setQueryParams({
      size: params.size,
      page: params.page,
      productName: params.productName,
      dataInicio: queryParams.from,
      dataFim: queryParams.to,
      sort: params.sort,
    });

    return params;
  };

  const handleExport = async () => {
    const defaultParams = {
      productName: queryParams.productName || "",
      dataInicio: queryParams.from
        ? queryParams.from + "T00:00:00.556"
        : initialDate + "T00:00:00.556",
      dataFim: queryParams.to
        ? queryParams.to + "T00:00:00.556"
        : finalDate + "T00:00:00.556",
      size: 9999,
      page: 0,
      sort: "created",
      rating: queryParams.rating || 5,
    };

    const dataExport = await reportEvaluationsStore.getList(defaultParams);

    if (dataExport) {
      const listExport = dataExport.data.content.map((item: RatingsReport) => ({
        name: item.name,
        total: item.total,
        rating: item.rating,
      }));

      exportToExcel(listExport, ["Nome", "Total de Avaliações", "Avaliação"], "Tabela de Relatório de Avaliações.xlsx");
    }
  };

  return (
    <>
      <BreadCrumbComponent breadCrumbList={breadCrumb} />

      <Permission permissionKeys={[PermissionReports.RATINGS_BY_PRODUCTS]} fallback={NotPermissionComponent()}>
        <ContentBox className="mb-4 p-6" title="Relatório de Avaliações por Produto" description='Listagem de Avaliações por Produto'>
          {/* @ts-ignore */}
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {({ resetForm }) => (
              <Form>
                <div className="flex items-end w-full gap-4 pb-4">
                  <Permission permissionKeys={[PermissionProducts.GET]} inputType name="Buscar-Produtos">
                    <AutocompleteComponent
                      label="Buscar Produtos"
                      name="searchTerm"
                      placeholder="Digite o nome do Produto"
                      fetchOptions={handleSearch}
                      options={autocompleteOptions}
                    />
                  </Permission>
                  <DatePickerComponet label="Data inicial" name="date" />
                  <SelectComponent label="Avaliação" name="rating" options={ratingSelect} />
                </div>

                <div className="flex items-end gap-4 justify-end">
                  <Button onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                  <Button htmlType="submit" type="primary">Filtrar</Button>
                </div>
              </Form>
            )}
          </Formik>
        </ContentBox>

        <ContentBox className="mb-4 p-6" description='Relatório de Avaliações' actions={
          <>
            <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
          </>
        }>

          <Table
            className="table-custom"
            columns={columns}
            dataSource={reportList.map((item: any, index: number) => ({
              ...item,
              key: item.uuid || index,
            }))}
            loading={loading}
            onChange={onChange}
            size="middle"
            bordered={false}
            pagination={{
              current: page + 1,
              pageSize: size,
              total: totalElements,
              showSizeChanger: true,
              pageSizeOptions: ['20', '40', '60'],
            }}
            showSorterTooltip={{ target: 'sorter-icon' }}
          />
        </ContentBox>
      </Permission>
    </>
  );
});

export default ReportEvaluations;