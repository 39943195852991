import { DeleteOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import productStore from '../../../../stores/productStore';
import { getUniqueFilters, getUniquePropertyFilters } from '../../../../utils/functions';
import ContentBox from '../../../molecules/ContentBox';
import ModalComponent from '../../../organisms/ModalComponent';
import DrawerSpecification from './DrawerSpecification';
import ActionButton from '../../../atoms/ButtonComponent';
import Permission from '../../../molecules/Permission';
import { PermissionProperties } from '../../../../permissions/PermissionProperties';


interface DataType {
    propertyClass: any;
    productProperties: any;
    propertyValue: any;
    key: React.Key;
}

const TableSpecification: React.FC = observer(() => {
    const { product, loading } = productStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Tipo',
            dataIndex: 'propertyClass',
            key: 'propertyClass.className',
            filters: getUniquePropertyFilters<any, 'propertyClass', 'className'>(product?.productProperties, 'propertyClass', 'className'),
            onFilter: (value, record) => record?.propertyClass?.className?.indexOf(value as string) === 0, // Acesse o nome do canal
            render: (item) => {
                return (
                    <>{item && <p>{item?.className}</p>}</>
                );
            },
        },
        {
            title: 'Valor',
            dataIndex: 'propertyValue',
            key: 'propertyValue',
            filters: getUniqueFilters(product?.productProperties, 'propertyValue'),
            onFilter: (value, record) => record.propertyValue.indexOf(value as string) === 0,
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        productStore.removeSpecification(itemSelected.uuid);
        setIsModalOpen(false);
    };


    return (
        <div>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Cadastro de Especificação" description='Listagem de especificações' actions={
                <>
                    <Permission permissionKeys={[PermissionProperties.GET]} buttonType><ActionButton icon actionType="create" onClick={() => setOpenModalRegister(true)}>Cadastrar</ActionButton></Permission>
                </>
            }>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={product?.productProperties?.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            </ContentBox>

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Epecificação <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <DrawerSpecification isModalOpen={openModalRegister} setIsModalOpen={setOpenModalRegister} />
        </div>
    );
});

export default TableSpecification;