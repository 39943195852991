import { Form, message } from 'antd';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { PermissionComponents } from '../../../../permissions/PermissionComponents';
import componentsStore from '../../../../stores/componentsStore';
import homeStore from '../../../../stores/homeStore';
import { ResponsiveString } from '../../../../utils/selects';
import ActionButton from '../../../atoms/ButtonComponent';
import InputNumberComponente from '../../../atoms/InputNumberComponet';
import SelectComponent from '../../../atoms/SelectComponent';
import ContentBox from '../../../molecules/ContentBox';
import Permission from '../../../molecules/Permission';
import PreviewComponents from '../../../organisms/ComponentesHome';
import DrawerComponent from '../DrawerComponent';
import ListComponents from '../ListComponents';
import { validationSchema } from './schema';

export const modelOptions = [
    { value: 'channel', label: 'Canal' },
    { value: 'subChannel', label: 'SubCanal' },
    { value: 'customer', label: 'Cliente' }
];

export const type = [
    { value: 'PRODUCT_CAROUSEL', label: 'Carrosel' },
    { value: 'BANNER_CAROUSEL', label: 'Imagem' },
];

const HomeInformation: React.FC = observer(() => {
    const { componentsPreview } = homeStore;
    const { componentsListSelect } = componentsStore;
    const [openModal, setOpenModal] = useState<boolean>();
    const [preview, setPreview] = useState<any>();
    const [componentsSelect, setComponentSelect] = useState<any>();
    const [typeSelect, setTypeSelect] = useState<any>();
    const [responsive, setResponsive] = useState<boolean>(true);

    const initialValues = {
        type: '',
        mobile: '',
        order: '',
        component: '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: any, resetForm: any) => {
        const addComponent = {
            order: values?.order,
            mobile: values?.mobile?.value === 'true' ? true : false,
            componentUuid: values?.component?.value,
            component: values?.component?.option,
            screen: true,
        }

        if (values.mobile === '') {
            message.error('Selecione um Responsivo');
            return;
        }

        if (values.order === '') {
            message.error('Selecione uma Ordenação');
            return;
        }

        if (values.type === '') {
            message.error('Selecione um Tipo');
            return;
        }

        if (preview) {
            const response = await homeStore.addComponent(addComponent);

            if (response) {
                setPreview(undefined);

                resetForm({
                    component: { value: '', label: '' },
                    type: { value: '', label: '' },
                });
            }
        } else {
            message.error('Selecione um Componente');
        }
    };

    const handleType = (value: any, resetForm: any) => {
        setComponentSelect([]);
        setPreview(undefined);
        setTypeSelect(value?.label);

        const filter = componentsListSelect.filter((item: any) => item?.option?.type === value?.value);
        setComponentSelect(filter);
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ values, resetForm }) => (
                    <Form>
                        <ContentBox className="mb-4 p-6 rounded-none" title="Cadastro de Componentes" description='Tela para cadastro de componentes' actions={
                            <ActionButton actionType="create" onClick={() => setOpenModal(true)}>Criar novo Component</ActionButton>
                        }>
                            <div className="flex items-end w-full gap-4">
                                <div className="w-full">
                                    <SelectComponent onChange={(value, option) => setResponsive(value === 'true' ? true : false)} label="Responsivo" name="mobile" options={ResponsiveString} />
                                </div>
                                <div className="w-full">
                                    <InputNumberComponente label="Ordenação" name="order" id="order" />
                                </div>
                                <div className="w-full">
                                    <SelectComponent onChange={(value, option) => handleType(option, resetForm)} label="Selecione o Tipo" name="type" options={type} />
                                </div>
                            </div>

                            <div className="flex items-end w-full gap-4 pt-4">
                                <div className="w-full">
                                    <Permission permissionKeys={[PermissionComponents.GET]} inputType name="Selecione o Componente">
                                        <SelectComponent disabled={typeSelect === undefined} onChange={(value, option) => setPreview(option?.option)} label="Selecione o Componente" name="component" options={componentsSelect} />
                                    </Permission>
                                </div>
                            </div>

                            <div className="flex gap-2 justify-end items-end pt-4">
                                <ActionButton icon actionType="primary" onClick={() => handleSubmit(values, resetForm)}>Adicionar Component</ActionButton>
                            </div>
                        </ContentBox>
                    </Form>
                )}
            </Formik>

            {preview &&
                <ContentBox className="mb-4 p-6 rounded-none" title="Preview dos Components" description='Visualização dos Componentes'>
                    <PreviewComponents isMobile={responsive} tag={preview.type === 'BANNER_CAROUSEL' ? preview.banners[0]?.tag : 'PRODUCT_CAROUSEL'} banners={preview.type === 'BANNER_CAROUSEL' ? preview.banners[0] : preview.category} bannerPage={false} />
                </ContentBox>
            }

            <ListComponents listComponents={componentsPreview.slice().sort((a: any, b: any) => a.order - b.order)} title="Componentes Web" isMobile={false} />
            <ListComponents listComponents={componentsPreview.slice().sort((a: any, b: any) => a.order - b.order)} title="Lista Responsivo" isMobile={true} />
            <DrawerComponent isModalDrawer={openModal} setIsDrawerOpen={setOpenModal} />
        </div>
    );
});

export default HomeInformation;