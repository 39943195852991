import Editor from "@monaco-editor/react";
import { Form, Formik } from 'formik';
import 'jsoneditor/dist/jsoneditor.css';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionTemplateEmail } from "../../../permissions/PermissionTemplateEmail";
import templateStore from '../../../stores/templateStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from "../../atoms/ButtonComponent";
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from "../../atoms/NotPermissionComponent";
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from "../../molecules/Permission";
import { useAuth } from "../../protected/ProviderAuth";
import { validationSchema } from './schema';
import TemplateSend from "./TemplateSend";
import PermissionComponent from "../../atoms/PermissionComponent";


const breadCrumb = [
  {
    title: <a href="/dashboard">Dashboard</a>,
  },
  {
    title: <a href="/template">Listagem de Templates</a>,
  },
  {
    title: <p>Editar Template de E-mail</p>,
  },
]

const CreateTemplateEmail: React.FC = observer(() => {
  const { uuid } = useParams<{ uuid: string }>();
  const { template, loading } = templateStore;
  const navigate = useNavigate();
  const [htmlCode, setHtmlCode] = useState("");
  const [jsonData, setJsonData] = useState<any>();
  const [htmlFromServer, setHtmlFromServer] = useState('');
  const nunjucks = require('nunjucks');
  nunjucks.configure('views', {
    autoescape: true,
    watch: true,
    noCache: true
  });
  const { hasPermission } = useAuth();

  useEffect(() => {
    const getInformation = async () => {
      if (uuid && hasPermission(PermissionTemplateEmail.GET_UUID)) {
        const response = await templateStore.getTemplateByUuid(uuid);
        const result = await templateStore.getBodyEmailData(response.type);

        if (result?.content.length > 0) {
          const jsonExample = JSON.parse(result?.content[0]?.body);
          const html = nunjucks.renderString(response?.body, { data: jsonExample });

          setHtmlFromServer(html);
          setJsonData(jsonExample);
        } else {
          const html = nunjucks.renderString(response?.body, { data: [] });
          setHtmlFromServer(html);
        }

        setHtmlCode(response?.body);
      }
    };

    getInformation();

    return () => {
      templateStore.reset();
    };
  }, []);


  const initialValues = {
    name: '',
    shortName: '',
    type: template?.type || ''
  };

  // Faz o filtro da tabela
  const handleSubmit = async (values: { name: string; shortName: string }) => {
    let response;

    const createProps = {
      ...template,
      body: htmlCode
    };

    response = await templateStore.editTemplate(createProps);

    if (!response.error) listRouter();
  };

  const listRouter = () => {
    navigate(`/template`);
  }

  const handleEditorDidMount = (editor: any, monaco: any) => {
    monaco.editor.setTheme("vs-dark");
  }

  const handleJsonChange = (updatedJson: any) => {
    setJsonData(updatedJson);
    const html = nunjucks.renderString(htmlCode, { data: updatedJson });
    setHtmlFromServer(html);
  };

  const changeHtml = (e: any) => {
    const nunjucks = require('nunjucks');
    const html = nunjucks.renderString(e, { data: jsonData });
    setHtmlCode(e);
    setHtmlFromServer(html);
  }


  return (
    <>
      <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionTemplateEmail.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

      {loading &&
        <ContentBox className="mb-4 p-6" title="Editar Template de E-mail" description='Tela editar template de E-mail'>
          <LoadingComponent />
        </ContentBox>
      }

      {!loading &&
        <Permission
          permissionKeys={[PermissionTemplateEmail.POST, PermissionTemplateEmail.PUT, PermissionTemplateEmail.GET_UUID]}
          fallback={<PermissionComponent />}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              <ContentBox className="mb-4 p-6" title="Editar Template de E-mail" description='Tela editar template de E-mail'>
                <div className="w-full flex flex-col gap-4">
                  <div className="flex gap-4">
                    <InputComponet disabled label="Tipo de Template*" name="type" id="type" />
                  </div>
                </div>
              </ContentBox>

              <ContentBox className="mb-4 p-6" title="Template de E-mail" description='Tela para cadastro de template de e-mail'>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <Editor
                      onMount={handleEditorDidMount}
                      height="600px"
                      width="100%"
                      onChange={(e: any) => changeHtml(e)}
                      defaultLanguage="html"
                      defaultValue={htmlCode}
                    />
                  </div>

                  <div className="col-span-6" style={{
                    flex: 1,
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: '600px',
                    background: '#1d1f21'
                  }}>
                    <ReactJson src={jsonData} theme="tomorrow"
                      onEdit={(edit) => {
                        handleJsonChange(edit.updated_src);
                      }} />
                  </div>
                </div>
              </ContentBox>


              <TemplateSend htmlFromServer={htmlFromServer} jsonData={jsonData} />

              <div className="flex justify-end">
                <Permission permissionKeys={[PermissionTemplateEmail.PUT, PermissionTemplateEmail.POST]} buttonType>
                  <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                </Permission>
              </div>
            </Form>
          </Formik>
        </Permission>
      }
    </>
  );
});

export default CreateTemplateEmail;