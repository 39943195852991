import { DeleteOutlined } from '@ant-design/icons';
import { Button, Image, Space, Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import productStore from '../../../../stores/productStore';
import { getUniqueFilters } from '../../../../utils/functions';
import ContentBox from '../../../molecules/ContentBox';
import ModalComponent from '../../../organisms/ModalComponent';
import DrawerImagemUpload from './DrawerUploadImage';
import DrawerUploadUrl from './DrawerUploadUrl';

interface DataType {
    path: any;
    ordering: any;
    center: any;
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

const UplodFileProduct: React.FC = observer(() => {
    const { loading, product } = productStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [openModalRegisterUrl, setOpenModalRegisterUrl] = useState(false);

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Imagem',
            dataIndex: 'path',
            key: 'path',
            render: (path) => {
                return (
                    <Image
                        width={50}
                        src={path}
                    />
                );
            },
        },
        {
            title: 'Ordenação',
            dataIndex: 'ordering',
            key: 'ordering',
            filters: getUniqueFilters(product?.files, 'ordering'),
            onFilter: (value, record) => record.ordering.indexOf(value as string) === 0,
        },
        {
            title: 'Url Imagem',
            dataIndex: 'path',
            key: 'path',
            filters: getUniqueFilters(product?.files, 'path'),
            onFilter: (value, record) => record.path.indexOf(value as string) === 0,
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        productStore.removeImage(itemSelected);
        setIsModalOpen(false);
    };


    return (
        <div>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Cadastro de Imagem" description='Listagem de imagens cadastradas'>
                <div className="flex gap-4">
                    <Button onClick={() => setOpenModalRegister(true)}>Cadastrar imagem por Upload</Button>
                    <Button onClick={() => setOpenModalRegisterUrl(true)}>Cadastrar imagem por URL</Button>
                </div>

                <div className="mt-4">
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={product && product?.files.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={false}
                    />
                </div>

                <ModalComponent
                    title="Aviso"
                    content={<p>Você deseja excluir a Unidade <b>{itemSelected?.name}</b>?</p>}
                    isModalOpen={isModalOpen}
                    handleOk={handleDeleteItem}
                    handleCancel={() => setIsModalOpen(false)}
                />
            </ContentBox>

            <DrawerImagemUpload isModalOpen={openModalRegister} setIsModalOpen={setOpenModalRegister} />
            <DrawerUploadUrl isModalOpen={openModalRegisterUrl} setIsModalOpen={setOpenModalRegisterUrl} />
        </div>
    );
});

export default UplodFileProduct;