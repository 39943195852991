import axios from 'axios';
import Cookies from 'js-cookie';

// https://api-stg.3coracoesconecta.com.br/;
// https://tres-coracoes-api.dev.stoomlab.com.br/

// Criação da instância base do Axios
const BaseAPI = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://api-stg.3coracoesconecta.com.br/',  // URL base da API
    headers: {
        'Content-Type': 'application/json',
    },
});

// Intercepta todas as requisições para adicionar um token, se necessário
BaseAPI.interceptors.request.use(config => {
    const token = Cookies.get('auth_token');

    if (token) {
        config.headers.Authorization = token;
    }

    if (config?.headers) {
        config.headers['2fa'] = config.headers.code;
        delete config.headers.code;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// Intercepta todas as respostas para tratamento de erros global
BaseAPI.interceptors.response.use(
    (response) => {
        // Caso a resposta seja bem-sucedida, retorna a response
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Lógica para tratar erro de autenticação (ex: logout, redirecionar para login)
        }

        // Retorna o erro para que o chamador possa tratá-lo
        return Promise.reject(error);
    }
);

export default BaseAPI;
