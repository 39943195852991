import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class ProductService {
    async getList(params: any, type: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.PRODUCTS}/search/${type}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async createProduct(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.PRODUCTS}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editProduct(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.PRODUCTS}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async searchProduct(name: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SEARCH_PRODUCT}/search/MODEL?page=0&size=20&sort=created,desc&search=,name;${name}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async searchProductAllBySku(name: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SEARCH_PRODUCT}/search/ALL?page=0&size=20&sort=created,desc&search=,skuCode;${name}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async deleteProduct(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.PRODUCTS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async getProductByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.PRODUCTS}/${uuid}?by=uuid`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async getStock(params: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.PRODUCTS}/stock-online`, params);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    //Categoria
    async getProductByCategory(slug: string, params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.PRODUCTS}/${slug}/products`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async addCategoryProduct(categoryUuid: string, data: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.CATEGORIES}/${categoryUuid}/products`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }


    async removeCategoryProduct(categoryUuid: string, data: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.CATEGORIES}/${categoryUuid}/products`, { data });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }


    //Imagem
    async addImageURL(productUuid: string, file: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.FILES}/products/path/${productUuid}`, file, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Define o tipo de conteúdo correto
                },
            });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao Cadastrar" }; // Retorna um objeto de erro
        }
    }


    async addImageUpload(productUuid: string, file: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.FILES}/products/${productUuid}`, file, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Define o tipo de conteúdo correto
                },
            });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao Cadastrar" }; // Retorna um objeto de erro
        }
    }


    async deleteImage(uuidFile: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.FILES}/${uuidFile}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao Cadastrar" }; // Retorna um objeto de erro
        }
    }
}

export default new ProductService();