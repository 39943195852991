import { EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Rate, Space, Table, Tag, Tooltip } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { COLOR_TOOLTIP, DEFAULT_PAGE_SIZE, defaultparamsExport } from '../../../config/config';
import useQueryParams, { buildParamsFromQueryReceivedParams } from '../../../hooks/useQueryParams';
import { PermissionEvaluations } from '../../../permissions/PermissionEvaluations';
import evaluationStore from '../../../stores/evaluationStore';
import { Ratings } from '../../../types/Ratings';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import { ratingSelect } from '../../../utils/selects';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import ModalEvaluations from './components/ModalEvaluations';
import './styles.scss';
import PermissionComponent from '../../atoms/PermissionComponent';

interface DataType {
    rating: any;
    status: any;
    baseProductName: any;
    description: any;
    code: any;
    uuid: any;
    key: React.Key;
}

interface QueryParams {
    status: string;
    statuses: string;
    rating: string;
    size?: number;
    page?: number;
    sort?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Avaliações</p>,
    },
]

const statusOptions = [
    {
        value: 'PENDING',
        label: 'Pendente'
    },
    {
        value: 'ACCEPTED',
        label: 'Aprovado'
    },
    {
        value: 'REJECTED',
        label: 'Reprovado'
    }
];

const ListEvaluations: React.FC = observer(() => {
    const { evaluationList, loading, page, totalElements, defaultparams, sort, size } = evaluationStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const { hasPermission, isColumnVisible } = useAuth();

    const initialValues = {
        status: typeof queryParams.status === 'string'
            ? { value: queryParams.status, label: queryParams.status }
            : { value: '', label: '' },

        rating: typeof queryParams.rating === 'string'
            ? { value: queryParams.rating, label: queryParams.rating }
            : { value: '', label: '' },
    };


    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        statuses: 'statuses',
        rating: 'rating',
    };

    useEffect(() => {
        const paramsDefault = {
            size: DEFAULT_PAGE_SIZE,
            page: 0,
            sort: 'created,desc',
            statuses: queryParams.status || 'ACCEPTED,PENDING,REJECTED',
            rating: queryParams.rating,
        };

        const params = buildParamsFromQueryReceivedParams(queryParams, mappings, paramsDefault);

        const fetchData = async () => {
            if (hasPermission(PermissionEvaluations.GET)) {
                await evaluationStore.getList(params);
            }
        };

        fetchData();
    }, []);


    // Interação da Tabela como sorte
    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        //@ts-ignore
        const params = buildParams(pagination, sorter, queryParams);
        await evaluationStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort, // Use o sort da tabela se disponível
            statuses: queryParams.status || 'ACCEPTED,PENDING,REJECTED',
            rating: queryParams.rating || ''
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            statuses: queryParams.status,
            rating: params.rating,
        });

        return params;
    };


    const desc = ['terrível', 'ruim', 'normal', 'bom', 'otimo'];


    const getStatusTag = (status: string) => {
        let color, text;
        switch (status) {
            case 'ACCEPTED':
                color = 'green';
                text = 'Aprovado';
                break;
            case 'PENDING':
                color = 'orange';
                text = 'Pendente';
                break;
            case 'REJECTED':
                color = 'red';
                text = 'Reprovado';
                break;
            default:
                color = 'gray';
                text = 'Desconhecido';
                break;
        }
        return { color, text };
    };

    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Avaliação',
            dataIndex: 'rating',
            key: 'rating',
            sorter: true,
            filters: getUniqueFilters(evaluationList, 'rating'),
            onFilter: (value, record) => record.rating === value,
            defaultSortOrder: getSortOrder('rating', queryParams.sort),
            render: (text) => {
                return (
                    <>{<Rate className="rating" disabled={true} tooltips={desc} value={text} />}</>
                );
            },
        },
        {
            title: 'Nome do Produto',
            dataIndex: 'baseProductName',
            key: 'baseProductName',
            sorter: true,
            filters: getUniqueFilters(evaluationList, 'baseProductName'),
            onFilter: (value, record) => record.baseProductName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('baseProductName', queryParams.sort),
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            defaultSortOrder: getSortOrder('description', queryParams.sort),
            render: (description) => (
                <Tooltip color={COLOR_TOOLTIP} title={description}>
                    <div className="max-w-[250px] whitespace-nowrap overflow-hidden text-ellipsis">
                        <span >{description}</span>
                    </div>
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            filters: getUniqueFilters(evaluationList, 'status'),
            onFilter: (value, record) => record.status.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('status', queryParams.sort),
            render: (status) => {
                const { color, text } = getStatusTag(status); // Chama a função externa
                return (
                    <Tag color={color}>
                        <p>{text}</p>
                    </Tag>
                );
            },
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionEvaluations.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <div onClick={() => showModal(record)}>
                        <div><EditOutlined /></div>
                    </div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleSubmit = async (values: { status: { value: string; label: string }; rating: { value: string; label: string } }) => {
        const newParams: any = {
            status: values.status.value || undefined,
            rating: values.rating.value || undefined,
        };

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort || 'created,desc',
            statuses: values.status.value || 'ACCEPTED,PENDING,REJECTED',
            rating: values.rating.value || 5,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await evaluationStore.getList(params);
    };

    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL
        await evaluationStore.getList(defaultparams);

        const newInitialValues = {
            name: '',
            code: '',
        };

        //@ts-ignore
        resetForm(newInitialValues);
    };

    const handleExport = async () => {
        const paramsDefault = {
            size: DEFAULT_PAGE_SIZE,
            page: 0,
            sort: 'created,desc',
            statuses: queryParams.status || 'ACCEPTED,PENDING,REJECTED',
            rating: queryParams.rating,
        };

        const params = buildParamsFromQueryReceivedParams(queryParams, mappings, paramsDefault);
        const dataExport = await evaluationStore.getList(params);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: Ratings) => ({
                rating: item.rating,
                baseProductName: item.baseProductName,
                description: item.description,
                status: getStatusTag(item.status).text,
                created: formatDateString(item.created ?? ""),
            }));

            exportToExcel(listExport, ["Avaliação", "Nome do Produto", "Descrição", "Status", "Data de Criação"], "Tabela de Avaliação.xlsx");
        }
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            <Permission permissionKeys={[PermissionEvaluations.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Avaliações" description='Tela de listagem de Avaliações de Produtos'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4">
                                    <div className="w-full">
                                        <SelectComponent label="Status" name="status" options={statusOptions} />
                                    </div>
                                    <div className="w-full">
                                        <SelectComponent label="Avaliação" name="rating" options={ratingSelect} />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" description='Tabela de Avaliações' actions={
                    <>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>

                    <Table
                        className="table-custom"
                        columns={columns}
                        onChange={onChange}
                        dataSource={evaluationList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>


            {/* @ts-ignore */}
            <ModalEvaluations setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} itemSelected={itemSelected} />
        </>
    );
});

export default ListEvaluations;