import { Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import administratorStore from '../../../stores/administratorStore';
import authoritiesStore from '../../../stores/authoritiesStore';
import authStore from '../../../stores/authStore';
import perfisStore from '../../../stores/perfisStore';
import { mergeAuthorities } from '../../../utils/functionAuthorities';
import { transformToValueLabel } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import CheckboxComponent from '../../atoms/CheckboxComponent';
import InputComponent from '../../atoms/InputComponet';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import { editSchema, validationSchema } from './schema';
import SwitchComponent from '../../atoms/SwitchComponent';
import './styles.scss';
import { useAuth } from '../../protected/ProviderAuth';
import { PermissionAdministrator } from '../../../permissions/PermissionAdministrator';
import Permission from '../../molecules/Permission';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import { PermissionAuthorityGroups } from '../../../permissions/PermissionAuthorityGroups';
import { PermissionAuthority } from '../../../permissions/PermissionAuthority';
import PermissionComponent from '../../atoms/PermissionComponent';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/administradores">Listagem de Administradores</a>,
    },
    {
        title: <p>Cadastro de Administradores</p>,
    },
];

const CreateAdministrator: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { authoritiesList } = authoritiesStore;
    const { merchant } = authStore;
    const { administrator, loading, listGroup } = administratorStore;
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [listPerfil, setListPerfil] = useState<any>([]);
    const [groupSelected, setGroupSelected] = useState<any>();
    const { hasPermission } = useAuth();


    useEffect(() => {
        const defaultparams = {
            size: 999,
            page: 0,
            sort: 'created,desc',
            search: '',
        }

        const fetchData = async () => {
            const response = await authoritiesStore.getList();


            // Filtra as permissões que devem ser exibidas
            const permissionsForSelectedGroup = response.filter((auth: any) => auth.show === true && auth.groupedBy === 'Produtos');
            setPermissions(permissionsForSelectedGroup);
        };

        const fetchPerfilData = async () => {
            if (uuid && hasPermission(PermissionAdministrator.GET_UUID)) {
                const result = await administratorStore.getAdministratorByUuid(uuid);
                const authoritiesList = mergeAuthorities(result.user);
                const listGroup = transformToValueLabel(result.user.authorityGroups, 'uuid', 'name');
                setGroupSelected(listGroup);
                setSelectedItems(authoritiesList)
            }
        };

        const fetchPerfilList = async () => {
            const response = await perfisStore.getList(defaultparams);
            const filterActive = response.data.content.filter((item: any) => item.active === true)
            const list = transformToValueLabel(filterActive, 'uuid', 'name');
            setListPerfil(list)
        };
        fetchPerfilData();

        if (hasPermission(PermissionAuthority.GET)) {
            fetchPerfilList();
        }

        if (hasPermission(PermissionAuthorityGroups.GET)) {
            fetchData();
        }

        return () => {
            administratorStore.reset();
        };
    }, [uuid]);

    const initialValues = {
        name: administrator?.name || '',
        username: administrator?.user?.username || '',
        email: administrator?.user?.email || '',
        password: '',
        confirmEmail: administrator?.user?.email || '',
        group: listGroup || '',
        active: administrator?.active || ''
    };


    const handleSubmit = async (values: {
        active: any; group: any, name: string, username: string, email: string, password: string, confirmEmail: string
    }) => {
        let response;

        const payload = {
            ...administrator,
            deleted: false,
            active: values.active || false,
            name: values.name,
            user: {
                ...administrator?.user,
                active: values.active || false,
                deleted: false,
                username: values.username,
                email: values.email,
                password: values.password || "temp",
                authorities: [],
                authorityGroups: []
            },
            merchant: merchant,
            username: values.username,
        }

        if (uuid) {
            response = await administratorStore.editAdministrator(payload, uuid, groupSelected);
        } else {
            response = await administratorStore.createAdministrator(payload, values?.group);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/administradores`);
    };

    const uniqueGroups = [...new Set(authoritiesList.filter((auth: any) => auth.show).map((auth: any) => auth.groupedBy))];
    const sortedItems = [...uniqueGroups].sort((a: any, b: any) => a.localeCompare(b));
    const sortedItemsPermission = [...permissions].sort((a: any, b: any) => a.description.localeCompare(b.description));


    const handleGroupClick = (group: any) => {
        const permissionsForSelectedGroup = authoritiesList.filter((auth: any) => auth.groupedBy === group && auth.show === true);
        setPermissions(permissionsForSelectedGroup);
    };

    const countItemsInGroup = (group: string) => {
        return selectedItems?.filter((item: any) => item.groupedBy === group).length;
    };

    const selectGroup = (values: any[]) => {
        setGroupSelected(values);

        // Unificar todos os authorities em um único array
        const allAuthorities = values.flatMap((item) =>
            (item.option?.authorities || []).map((authority: any) => ({
                ...authority,
                source: true // Adiciona a propriedade que indica que veio de um grupo
            }))
        );

        // Remover duplicatas usando o 'uuid' como identificador único
        const uniqueAuthorities = allAuthorities.reduce((acc: any[], authority: any) => {
            if (!acc.some((existing: any) => existing.uuid === authority.uuid)) {
                acc.push(authority);
            }
            return acc;
        }, []);

        // Atualizar o estado com os authorities únicos e a flag fromGroup
        setSelectedItems(uniqueAuthorities);
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionAdministrator.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Administradores" description='Tela para cadastro de Administradores'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionAdministrator.POST, PermissionAdministrator.PUT, PermissionAdministrator.GET_UUID]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={uuid ? editSchema : validationSchema}
                    >
                        {() => {
                            const onChange = (item: any) => {
                                setSelectedItems((prevSelectedItems: any) => {
                                    const itemWithSource = {
                                        ...item,
                                        source: item.fromGroup ?? false // Define 'fromGroup' como 'false' se não estiver definido
                                    };

                                    if (prevSelectedItems.some((selectedItem: any) => selectedItem.uuid === itemWithSource.uuid)) {
                                        // Remove o item se já estiver na lista
                                        return prevSelectedItems.filter((selectedItem: any) => selectedItem.uuid !== itemWithSource.uuid);
                                    } else {
                                        // Adiciona o item com 'fromGroup' definido
                                        return [...prevSelectedItems, itemWithSource];
                                    }
                                });
                            };

                            return (
                                <Form>
                                    <ContentBox className="mb-4 p-6" title="Cadastro de Administradores" description='Tela para cadastro de Administradores' actions={
                                        <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                                    }>
                                        <div className="flex gap-3 mb-3">
                                            <InputComponent label="Nome" name="name" id="name" />
                                            <InputComponent disabled={uuid ? true : false} label="Usuario" name="username" id="username" />
                                            <InputComponent disabled={uuid ? true : false} label="E-mail*" name="email" id="email" />
                                        </div>
                                        <div className="grid grid-cols-12 gap-3">
                                            <div className="col-span-4">
                                                {!uuid ? <InputComponent label="Confirmar E-mail" name="confirmEmail" id="confirmEmail" /> : <></>}
                                            </div>
                                            <div className="col-span-4">
                                                {!uuid ? <InputComponent label="Senha" name="password" id="password" /> : <></>}
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <Permission permissionKeys={[PermissionAuthorityGroups.GET]} inputType name="Grupo de Usúarios">
                                                <SelectComponent defaultValue={listGroup} onChange={(value, option) => selectGroup(option)} mode="multiple" label="Grupo de Usúarios" name="group" options={listPerfil} />
                                            </Permission>
                                        </div>
                                    </ContentBox>

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-4">
                                            <Permission permissionKeys={[PermissionAuthority.GET]} fallback={<PermissionComponent />}>
                                                <ContentBox className="p-6">
                                                    <div className="flex flex-col gap-3 perfil-container">
                                                        {sortedItems && sortedItems.map((group: any) => (
                                                            <div className="cursor-pointer flex gap-2 items-center" key={group} onClick={() => handleGroupClick(group)}>
                                                                <div className={`rounded w-6 flex items-center justify-center text-sm ${countItemsInGroup(group) > 0 ? 'bg-primary-color text-white' : 'bg-gray-200 text-black'
                                                                    }`}>
                                                                    {countItemsInGroup(group)} {/* Contador de itens */}
                                                                </div>
                                                                <p className="text-sm">{group}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </ContentBox>
                                            </Permission>
                                        </div>
                                        <div className="col-span-8">
                                            <Permission permissionKeys={[PermissionAuthority.GET]} fallback={<PermissionComponent />}>
                                                <ContentBox className="mb-4 p-6" title={permissions && permissions[0]?.groupedBy} description='Selecione um Grupo de Permissões para visualizar as autorizações na listagem a baixo'>
                                                    <div className="flex flex-col gap-2 permissions-list">
                                                        {sortedItemsPermission && sortedItemsPermission.map((item: any, index: number) => (
                                                            <div className="flex gap-2" key={index}>
                                                                <CheckboxComponent disabled={true} label={`${item.description} (${item.action})`} key={item.uuid} onChange={() => onChange(item)} checked={selectedItems.some((selected: any) => selected?.uuid === item.uuid)} />
                                                                {selectedItems.some((selected: any) => selected.uuid === item.uuid && selected.source) && (
                                                                    <Tag color="green">Permissão de Grupo</Tag>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </ContentBox>
                                            </Permission>

                                            <div className="flex justify-end">
                                                <Permission permissionKeys={[PermissionAdministrator.PUT, PermissionAdministrator.POST]} buttonType>
                                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                                </Permission>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateAdministrator;
