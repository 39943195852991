import { Select, Typography } from 'antd';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import './styles.scss';

interface SelectComponentProps {
    label?: string;
    name: string;
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: any;
    options: { value: any; label: string; disabled?: boolean }[];
    size?: string;
    mode?: string;
    onChange?: (value: string, option: any) => void;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
    label,
    name,
    size,
    placeholder,
    disabled,
    defaultValue,
    options,
    mode,
    onChange,
}) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = (value: string, option: any) => {
        // Atualiza o Formik com o valor selecionado
        setFieldValue(name, {
            value: option?.value === undefined || option?.value === '' ? option?.label : option?.value,
            label: option?.label,
            option: option?.option,
        });

        // Executa o onChange passado via props, se disponível
        onChange?.(value, option);
    };

    return (
        <div className="flex flex-col w-full select-container">
            {label && <Typography.Title level={5}>{label}</Typography.Title>}
            <Field name={name}>
                {({ field, meta }: any) => (
                    <>
                        <Select
                            {...field}
                            onChange={handleChange}
                            placeholder={placeholder}
                            mode={mode}
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            filterSort={(optionA, optionB) =>
                                //@ts-ignore
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            defaultValue={defaultValue}
                            value={field.value?.value || undefined}
                            size={size || "large"}
                            options={options}
                            disabled={disabled}
                            status={meta.touched && meta.error ? 'error' : ''}
                        />
                        {meta.touched && meta.error?.value && (
                            <Typography.Text type="danger">{meta.error?.value}</Typography.Text>
                        )}
                    </>
                )}
            </Field>
        </div>
    );
};

export default SelectComponent;
