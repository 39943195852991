import { Button, Drawer } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/config';
import { PermissionEvaluations } from '../../../../../permissions/PermissionEvaluations';
import evaluationStore from '../../../../../stores/evaluationStore';
import InputComponent from '../../../../atoms/InputComponet';
import NotPermissionComponent from '../../../../atoms/NotPermissionComponent';
import SelectComponent from '../../../../atoms/SelectComponent';
import TextAreaComponent from '../../../../atoms/TextAreaComponent';
import Permission from '../../../../molecules/Permission';

export const statusOptions = [
    {
        value: 'PENDING',
        label: 'Pendente'
    },
    {
        value: 'ACCEPTED',
        label: 'Aprovado'
    },
    {
        value: 'REJECTED',
        label: 'Reprovado'
    }
];


const paramsDefault = {
    size: DEFAULT_PAGE_SIZE,
    page: 0,
    sort: 'created,desc',
    statuses: 'ACCEPTED,PENDING,REJECTED',
};

const ModalEvaluations: React.FC = observer(({ isModalOpen, setIsModalOpen, itemSelected }: any) => {

    const handleSubmit = async (values: { status: any }) => {
        const status = values.status.value;
        const response = await evaluationStore.updateEvaluations(status, itemSelected.uuid);
        if (!response.error) {
            evaluationStore.getList(paramsDefault);
            setIsModalOpen(false);
        }
    };

    const initialValuesForm = {
        title: itemSelected?.title || '',
        baseProductName: itemSelected?.baseProductName || '',
        email: itemSelected?.email || '',
        rating: itemSelected?.rating || '',
        status: { value: itemSelected?.status, label: '' },
        description: itemSelected?.description || '',
    };

    return (
        <Permission
            permissionKeys={[PermissionEvaluations.GET_UUID]}
            fallback={NotPermissionComponent()}
        >
            <Formik initialValues={initialValuesForm} onSubmit={handleSubmit} enableReinitialize>
                {({ submitForm }) => ( // Aqui está o submitForm
                    <Drawer
                        title="Atualização de Avaliação"
                        width={540}
                        onClose={() => setIsModalOpen(false)}
                        open={isModalOpen}
                        footer={
                            <div className="flex gap-4 justify-end">
                                <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                                <Button type="primary" onClick={submitForm}> {/* Agora funciona */}
                                    Cadastrar
                                </Button>
                            </div>
                        }
                    >
                        <Form>
                            <div className="flex gap-4 flex-col">
                                <SelectComponent label="Avaliaçao" size="middle" name="status" options={statusOptions} />
                                <InputComponent size="middle" disabled={true} label="Título" name="title" id="basePrtitleoductName" />
                                <InputComponent size="middle" disabled={true} label="Nome do Produto" name="baseProductName" id="baseProductName" />
                                <InputComponent size="middle" disabled={true} label="E-mail" name="email" id="email" />
                                <TextAreaComponent size="middle" disabled={true} label="Descrição" name="description" id="description" />
                                <InputComponent size="middle" disabled={true} label="Avaliaçao" name="rating" id="rating" />
                            </div>
                        </Form>
                    </Drawer>
                )}
            </Formik>
        </Permission>
    );
});

export default ModalEvaluations;
