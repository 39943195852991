const MANAGER = "manager";

const URLS = {
    ACCOUNTS: `${MANAGER}/accounts`,
    AUTHENTICATION: `administrator/2fa`,
    AUTHORITY: `${MANAGER}/authorities`,
    AUTHORITY_GROUPS: `${MANAGER}/authority-groups`,
    BANNERS: `${MANAGER}/banners`,
    BEST_SELLING_PRODUCTS: `${MANAGER}/reports/best-selling-products`,
    CAMPAIGNS: `${MANAGER}/campaigns`,
    CATEGORIES: `${MANAGER}/categories`,
    CHANNELS: `${MANAGER}/channel`,
    COLORS: `${MANAGER}/colors`,
    CONFIG: `${MANAGER}/config`,
    COMPONENTS: `${MANAGER}/components`,
    COUPONS: `${MANAGER}/voucherBatches`,
    CUSTOMERS: `${MANAGER}/customers`,
    DASHBOARD: `${MANAGER}/reports/dashboard`,
    EVALUATIONS: `${MANAGER}/products/evaluations`,
    FILES: `${MANAGER}/files`,
    FILE_BANNER: `${MANAGER}/files?metaTags=`,
    FORGET_PASSWORD: '/forget-password',
    GENERAL_SALES: `${MANAGER}/reports/sales-general`,
    HOMES: `${MANAGER}/homes`,
    INSTITUTIONAL: `${MANAGER}/institutional`,
    LOG: `${MANAGER}/audit/all_reviews`,
    LOGIN: `login`,
    MATERIAL: `${MANAGER}/material`,
    MULTIPLE: `${MANAGER}/trescoracoesconfig`,
    ORDER: `${MANAGER}/orders`,
    ORDERS: `${MANAGER}/orders`,
    PAYMENT: `${MANAGER}/payment-setup`,
    PRODUCTS: `${MANAGER}/products`,
    PROPERTIES: `${MANAGER}/properties`,
    REPORT_CAMPAIGNS: `${MANAGER}/reports/campaigns`,
    REPORT_CARD: `${MANAGER}/reports/abandoned-carts-general`,
    REPORT_CUPOM: `${MANAGER}/reports/vouchers`,
    REPORT_EVALUATIONS: `products/search/evaluations`,
    REPORT_LOGIN: `${MANAGER}/reports/logins`,
    SALES_ABANDONED_CARTS: `${MANAGER}/reports/abandoned-carts`,
    SALES_BY_CATEGORY: `${MANAGER}/reports/best-selling-categories`,
    SAVE_MULTIPLE: `${MANAGER}`,
    SEARCH_PRODUCT: `${MANAGER}/products`,
    SHIPPING: `${MANAGER}/shipping`,
    SIZES: `${MANAGER}/sizes`,
    STAMP: `${MANAGER}/stamps`,
    SUBCHANNELS: `${MANAGER}/sub_channel`,
    TEMPLATE_EMAIL: `${MANAGER}/template-email`,
    VOLTAGE: `${MANAGER}/voltage`,



};

export default URLS;