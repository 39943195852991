import { EyeOutlined, FileDoneOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Space, Table, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE, defaultparamsExport } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import CustomerModel from '../../../models/CustomerModel';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';
import channelStore from '../../../stores/channelStore';
import customerStore from '../../../stores/customerStore';
import subChannel from '../../../stores/subChannel';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder } from '../../../utils/functions';
import { StatusActive } from '../../../utils/selects';
import { getStatusColor } from '../../../utils/statusColorMapper';
import { formatDocument } from '../../../utils/TextFormatters';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalCliente from '../../organisms/ModalCliente';
import { useAuth } from '../../protected/ProviderAuth';
import { PermissionChannel } from '../../../permissions/PermissionChannel';
import { PermissionSubChannel } from '../../../permissions/PermissionSubChannel';
import PermissionComponent from '../../atoms/PermissionComponent';

interface DataType {
    subChannel: any;
    channel: any;
    active: boolean | React.Key;
    document: any;
    firstName: any;
    key: React.Key;
    nameTag: string;
    responsive: boolean;
    tag: string;
    uuid: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Clientes</p>,
    },
]

const ListCustomer: React.FC = observer(() => {
    const { customerList, loading, page, totalElements, defaultparams, sort, size } = customerStore;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<any>();
    // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    // const [itemSelectedDelete, setItemSelectedDelete] = useState<any>();
    // const navigate = useNavigate();
    // const listRouter = () => { navigate(`/cliente/cadastro`); }
    const [queryParams, setQueryParams] = useQueryParams();
    const { selectSubChannelList } = subChannel;
    const { selectChannelList } = channelStore;
    const { hasPermission, isColumnVisible } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        firstName: 'firstName',
        document: 'document',
        corporateName: 'corporateName',
        seller: 'seller',
        clientExternal: 'clientExternal',
        subChannel_channel_name: 'subChannel_channel_name',
        subChannel_name: 'subChannel_name',
        center: 'center',
        tableId: 'tableId'
    };

    const defaultparamsList = {
        size: 999999,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionCustomer.GET)) {
                await customerStore.getList(params);
            }

            if (hasPermission(PermissionChannel.GET)) {
                await channelStore.getList(defaultparamsList);
            }

            if (hasPermission(PermissionSubChannel.GET)) {
                await subChannel.getList(defaultparamsList);
            }
        };

        fetchData();
    }, []);

    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome Fantasia',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
            defaultSortOrder: getSortOrder('firstName', queryParams.sort),
        },
        {
            title: 'CNPJ',
            dataIndex: 'document',
            key: 'document',
            sorter: true,
            defaultSortOrder: getSortOrder('document', queryParams.sort),
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{formatDocument(text)}</p>}</>
                );
            },
        },
        {
            title: 'Canal',
            dataIndex: 'subChannel',
            key: 'subChannel.channel',
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.channel.name}</p>}</>
                );
            },
        },
        {
            title: 'SubCanal',
            dataIndex: 'subChannel',
            key: 'subChannel',
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.name}</p>}</>
                );
            },
        },
        {
            title: 'Centro Tabela',
            dataIndex: 'center',
            key: 'center',
            render: (item, record) => {
                return (
                    //@ts-ignore
                    <>{item && <p>{`${record?.center}/${record?.tableId ? record?.tableId : ''}`}</p>}</>
                );
            },
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Ativos',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            defaultSortOrder: getSortOrder('active', queryParams.sort),
            render: (active) => {
                let text = active ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Multiplo',
            dataIndex: 'clientUnitsActive',
            key: 'clientUnitsActive',
            sorter: true,
            render: (item) => {
                let text = item === 'IC' ? 'Inativo' : 'Ativo';
                return (
                    <Tag color={getStatusColor(text)}>{text}</Tag>
                );
            },
        },
        // {
        //     title: 'Ativo',
        //     dataIndex: 'active',
        //     key: 'active',
        //     align: 'center',
        //     width: 30,
        //     render: (item, record) => (
        //         <Switch defaultChecked={item} onChange={() => changeStatusCustomer(record)} />
        //     ),
        // },
        {
            title: 'Visualizar',
            dataIndex: 'view',
            key: 'view',
            align: 'center',
            width: 30,
            hidden: !isColumnVisible(PermissionCustomer.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <div onClick={() => showModal(record)}>
                        <div><EyeOutlined /></div>
                    </div>
                </Space>
            ),
        },
        {
            title: 'Pedidos',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            width: 30,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`cliente/detalhes/${record.uuid}`}>
                        <div><FileDoneOutlined /></div>
                    </a>
                </Space>
            ),
        },
        // {
        //     title: 'Editar',
        //     dataIndex: 'edit',
        //     key: 'edit',
        //     align: 'center',
        //     width: 40,
        //     hidden: !isColumnVisible(PermissionCustomer.GET_UUID),
        //     render: (text, record) => (
        //         <Space className="cursor-pointer">
        //             <a href={`/cliente/editar/${record.uuid}`}>
        //                 <div><EditOutlined /></div>
        //             </a>
        //         </Space>
        //     ),
        // },
        // {
        //     title: 'Deletar',
        //     dataIndex: 'delete',
        //     key: 'delete',
        //     align: 'center',
        //     width: 40,
        //     hidden: !isColumnVisible(PermissionCustomer.DELETE),
        //     render: (text, record) => (
        //         <Space className="cursor-pointer" onClick={() => showDeleteModal(record)}>
        //             <div ><DeleteOutlined /></div>
        //         </Space>
        //     ),
        // },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    // const handleDeleteItem = async () => {
    //     const response = await customerStore.deleteCustomer(itemSelectedDelete.uuid);

    //     if (!response?.error) {
    //         customerStore.getList(defaultparams);
    //         setIsDeleteModalOpen(false);
    //     }
    // };

    const initialValues: any = {
        firstName: queryParams.firstName || '',
        document: queryParams.document || '',
        seller: queryParams.seller || '',
        center: queryParams.center || '',
        tableId: queryParams.tableId || '',
        clientExternal: queryParams.clientExternal || '',
        active: typeof queryParams?.active === 'string'
            ? { value: queryParams?.active, label: queryParams?.active }
            : { value: '', label: '' },
        subChannel_channel_name: typeof queryParams.subChannel_channel_name === 'string'
            ? { value: queryParams.subChannel_channel_name, label: queryParams.subChannel_channel_name }
            : { value: '', label: '' },
        subChannel_name: typeof queryParams.subChannel_name === 'string'
            ? { value: queryParams.subChannel_name, label: queryParams.subChannel_name }
            : { value: '', label: '' },
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: any) => {
        const newParams: any = {
            firstName: values.firstName || undefined,
            document: values.document.replace(/\D/g, '') || undefined,
            corporateName: values.corporateName || undefined,
            seller: values.seller || undefined,
            clientExternal: values.clientExternal || undefined,
            subChannel_channel_name: values?.subChannel_channel_name?.label || undefined,
            subChannel_name: values?.subChannel_name?.label || undefined,
            active: values?.active?.value || undefined,
            center: values?.center || undefined,
            tableId: values?.tableId || undefined,
        };

        const search = [
            values.firstName ? `,firstName:"${values.firstName}"` : null,
            values.document ? `;document;"${values.document.replace(/\D/g, '')}"` : null,
            values.corporateName ? `;corporateName:"${values.corporateName}"` : null,
            values.seller ? `;seller:"${values.seller}"` : null,
            values.center ? `;center:"${values.center}"` : null,
            values.tableId ? `;tableId:"${values.tableId}"` : null,
            values.clientExternal ? `;clientExternal:"${values.clientExternal}"` : null,
            values.active.value ? `;active:"${values.active.value}"` : null,
            values.subChannel_name.value ? `;subChannel_name:"${values?.subChannel_name?.label}"` : null,
            values.subChannel_channel_name.value ? `;subChannel_channel_name:"${values?.subChannel_channel_name?.label}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: size,
            page: page,
            sort: sort || 'created,desc',
            search: search,
        };

        setQueryParams(newParams);
        await customerStore.getList(params);
    };

    // Interação da Tabela como sorte
    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current ? pagination.current - 1 : 0;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            size: newPageSize || queryParams.size,
            page: Number(currentPage || queryParams.page || 0),
            sort: getSort || queryParams.sort || 'created,desc',
        };

        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
        });

        //@ts-ignore
        await customerStore.getList(params);
    };


    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: any) => {
        resetForm();
        setQueryParams({});
        await customerStore.getList(defaultparams);
    };

    const handleExport = async () => {
        const dataExport = await customerStore.getList(defaultparamsExport);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: CustomerModel) => ({
                firstName: item.firstName,
                corporateName: item.corporateName,
                document: item.document,
                canal:
                    item.subChannel?.channel !== undefined
                        ? item.subChannel?.channel?.name
                        : '',
                subCanal:
                    item.subChannel?.name !== undefined ? item.subChannel?.name : '',
                customerType: item?.customerType?.name,
                paymentForm: item.paymentForm,
                paymentDeadline: item.paymentDeadline,
                email: item.user ? item?.user?.email : '',
                emailNf: item.emailNf,
                contactName: item.contactName,
                mobile: item.mobile,
                mobileWhatsapp: item?.mobileWhatsapp === true ? 'Sim' : 'Não',
                phone: item.phone,
                businessContact: item.businessContact,
                phoneCommercial: item.phoneCommercial,
                cellphoneCommercial: item.cellphoneCommercial,
                contactFinancial: item.contactFinancial,
                phoneFinancial: item.phoneFinancial,
                cellphoneFinancial: item.cellphoneFinancial,
                billingAddress: '-',
                streetBillingAddress: item?.billingAddress?.street,
                numberBillingAddress: item?.billingAddress?.number,
                blockBillingAddress: item?.billingAddress?.block,
                cityBillingAddress: item?.billingAddress?.city,
                stateBillingAddress: item?.billingAddress?.state,
                countryBillingAddress: item?.billingAddress?.country,
                addressComplemenBillingAddresst:
                    item?.billingAddress?.addressComplement,
                zipCodeBillingAddress: item?.billingAddress?.zipCode,
                mainAddress: '-',
                streetMainAddress: item?.mainAddress?.street,
                numberMainAddress: item?.mainAddress?.number,
                blockMainAddress: item?.mainAddress?.block,
                cityMainAddress: item?.mainAddress?.city,
                stateMainAddress: item?.mainAddress?.state,
                countryMainAddress: item?.mainAddress?.country,
                addressComplementMainAddress: item?.mainAddress?.addressComplement,
                zipCodeMainAddress: item?.mainAddress?.zipCode,
                status: item?.status,
                active: item.active ? "Sim" : "Não",
                created: formatDateString(item.created?.toString() ?? ""),
                externalId: item?.externalId,
            }));

            exportToExcel(listExport, [
                'Nome Fantasia',
                'Razão Social',
                'CNPJ',
                'Canal',
                'Sub Canal',
                'Identificador de Preço',
                'Formulário de Pagamento',
                'Prazo de Pagamento',
                'Email',
                'Email Nota',
                'Nome Contato',
                'Celular',
                'Whatsapp',
                'Telefone Fixo',
                'Contato Comercial',
                'Telefone Comercial',
                'Celular Comercial',
                'Contato Financeiro',
                'Telefone Financeiro',
                'Celular Financeiro',
                'Endereço de Entrega',
                'Endereço',
                'Número',
                'Bairro',
                'Complemento',
                'Cidade',
                'Estado',
                'Pais',
                'CEP',
                'Endereço de Cobrança',
                'Endereço',
                'Número',
                'Bairro',
                'Complemento',
                'Cidade',
                'Estado',
                'Pais',
                'CEP',
                'Status do Cadastro',
                'Status',
                'Data de Criação',
                'Código BP',
            ], "Tabela de Cliente.xlsx");
        }
    };


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionCustomer.POST} />

            <Permission permissionKeys={[PermissionCustomer.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Cliente" description='Tela de listagem de Clientes'>
                    <Formik
                        //@ts-ignore
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <InputComponet label="Nome Fantasia" name="firstName" id="firstName" />
                                    </div>
                                    <div className="w-full">
                                        <InputComponet label="CNPJ" name="document" id="document" />
                                    </div>
                                    <div className="w-full">
                                        <SelectComponent label="Status" name="active" options={StatusActive} />
                                    </div>
                                </div>

                                <div className="flex items-end w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <InputComponet label="Código da Matrícula" name="seller" id="seller" />
                                    </div>
                                    <div className="w-full">
                                        <InputComponet label="Código BP" name="clientExternal" id="clientExternal" />
                                    </div>
                                    <div className="w-full col-span-4">
                                        <Permission permissionKeys={[PermissionChannel.GET]} inputType name="Canal">
                                            <SelectComponent label="Canal" name="subChannel_channel_name" options={selectChannelList} />
                                        </Permission>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 gap-4 pb-4">
                                    <div className="w-full col-span-4">
                                        <Permission permissionKeys={[PermissionSubChannel.GET]} inputType name="SubCanal">
                                            <SelectComponent label="SubCanal" name="subChannel_name" options={selectSubChannelList} />
                                        </Permission>
                                    </div>
                                    <div className="w-full col-span-4">
                                        <InputComponet label="Centro" name="center" id="center" />
                                    </div>
                                    <div className="w-full col-span-4">
                                        <InputComponet label="Tabela" name="tableId" id="tableId" />
                                    </div>
                                </div>

                                <div className="flex items-end gap-4 justify-end ">
                                    <Button onClick={() => handleClearAndSearch(resetForm)}>
                                        Limpar
                                    </Button>
                                    <Button htmlType="submit" type="primary">Filtrar</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" description='Tela de listagem de Cliente' actions={
                    <>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={customerList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {/* @ts-ignore */}
            <ModalCliente setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} customer={itemSelected} />

            {/* <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o cliente <b>{itemSelectedDelete?.firstName}</b>?</p>}
                isModalOpen={isDeleteModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsDeleteModalOpen(false)}
            /> */}
        </>
    );
});

export default ListCustomer;