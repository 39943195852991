import { Button, message } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import authStore from '../../../stores/authStore';
import bannerStore from '../../../stores/bannerStore';
import { Status } from '../../../utils/selects';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import PreviewComponents from '../../organisms/ComponentesHome';
import DrawerImage from './DrawerImage';
import ModalBannerTag from './ModalBannerTag';
import { validationSchema } from './schema';
import TableBanners from './TableBanners';
import { useAuth } from '../../protected/ProviderAuth';
import { PermissionBanners } from '../../../permissions/PermissionBanners';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import Permission from '../../molecules/Permission';
import SwitchComponent from '../../atoms/SwitchComponent';
import { MobileIcon, WebIcon } from '../../molecules/IconComponent';
import { Responsive } from '../CreateConfiguration/TopBarConfiguration/DrawerRegisterTopBarLink';

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/canal">Listagem de Banners</a>,
    },
    {
        title: <p>Cadastro de Banner</p>,
    },
]

const CreateBanners: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { banner, loading, arrayImageUrl, previewImage } = bannerStore;
    const { merchant } = authStore;
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedTag, setSelectedTag] = useState();
    const [isDraweOpen, setIsDraweOpen] = useState<boolean>(false);
    const [isMobile, setMobile] = useState<boolean>(false);
    const { hasPermission } = useAuth();

    const initialValues = {
        nameTag: banner?.nameTag || '',
        active: banner?.active || '',
        tag: selectedTag || banner?.tag || '',
        responsive: banner?.responsive || '',
    };

    useEffect(() => {
        if (uuid && hasPermission(PermissionBanners.GET_UUID)) {
            const fetchData = async () => {
                const result = await bannerStore.getBannerByUuid(uuid);
                setSelectedTag(result.tag);
                setMobile(result.responsive);
            };
            fetchData();
        }

        setMobile(false);

        return () => {
            bannerStore.reset();
        };
    }, []);


    // Faz o filtro da tabela
    const handleSubmit = async (values: any) => {
        let response;

        if (!selectedTag) {
            message.error(`Selecione uma Tag`);
            return
        }

        const createProps = {
            ...banner,
            ...values,
            active: values?.active || false,
            merchant: merchant,
            tag: selectedTag,
            responsive: values?.responsive?.value
        };

        delete createProps['@class'];

        if (uuid) {
            response = await bannerStore.editBanner(createProps, uuid);
        } else {
            response = await bannerStore.createBanner(createProps);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/banners`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionBanners.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Banner" description='Tela para cadastro de Banner'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionBanners.POST, PermissionBanners.PUT, PermissionBanners.GET_UUID]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Cadastro de Banner" description='Tela para cadastro de Banner' actions={
                                <>
                                    <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                                </>
                            }>
                                <div className="flex gap-4 mb-4">
                                    <InputComponet label="Nome*" name="nameTag" id="nameTag" />
                                </div>
                                <div className="flex gap-4 items-end">
                                    <InputComponet disabled value={selectedTag} label="Tag" name="tag" id="tag" />
                                    <Button onClick={() => setIsModalOpen(true)} type="primary">Selecionar</Button>
                                </div>
                            </ContentBox>

                            <ContentBox className="mb-4 p-6" title="Informações de Banner" description='Clique para selecionar o Modelo' actions={
                                <Permission buttonType permissionKeys={['dfhdsjkfhksdjf']}>
                                    <div className="flex gap-2">
                                        <ActionButton icon actionType="create" onClick={() => setIsDraweOpen(true)}>Adicionar Imagem</ActionButton>
                                    </div>
                                </Permission>
                            }>
                                <TableBanners />
                            </ContentBox>

                            <ContentBox className="mb-4 p-6 col-span-12" title="Visualização do Banner" description="Informações de Banner" actions={
                                <div className="flex gap-3">
                                    <div onClick={() => setMobile(true)} className={`p-2 cursor-pointer rounded ${isMobile ? 'border-2 border-blue-500' : 'border border-slate-200'}`}>
                                        <MobileIcon />
                                    </div>
                                    <div onClick={() => setMobile(false)} className={`p-2 cursor-pointer rounded ${!isMobile ? 'border-2 border-blue-500' : 'border border-slate-200'}`}>
                                        <WebIcon />
                                    </div>
                                </div>
                            }>
                                <PreviewComponents isMobile={isMobile} tag={selectedTag} banners={previewImage} bannerPage={true} />
                            </ContentBox>

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionBanners.PUT, PermissionBanners.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik>
                </Permission>
            }

            <ModalBannerTag isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setSelectedTag={setSelectedTag} selectedTag={selectedTag} />

            {/* @ts-ignore */}
            <DrawerImage tag={selectedTag} setIsModalOpen={setIsDraweOpen} isModalOpen={isDraweOpen} />
        </>
    );
});

export default CreateBanners;